<template>
  <Header/>

  <!-- container start -->
  <div id="container">

      <!-- contents start -->
      <div id="contents" class="main">

        <!-- main banner -->
        <div class="main-banner-area">
          <carousel
            :wrapAround='true'
            :pauseAutoplayOnHover='true'
            :autoplay='3000'
          >
            <slide v-for="slide in slides" :key="slide">
              <div class="carousel__item banner" :class="bannerId + slide.id">
                <span>{{ slide.title }}</span>
                <h2 v-html="slide.content"></h2>
                <div class="banner-txt" v-html="slide.text"></div>
              </div>
            </slide>

            <template #addons>
              <navigation />
              <pagination />
            </template>
          </carousel>
        </div>

        <!-- main business -->
        <div class="main-section bg-type-2">
          <div class="main-business-area">
            <div class="main-title">
                <span>Softempire Business</span>
                <h2>사업 분야</h2>
            </div>

            <div class="main-business-conts">
              <span class="img"><img src="@/assets/images/contents/ico_business_01.png" alt="streaming"></span>
              <h3>스트리밍 서비스</h3>
              <span class="info-txt">Streaming Service</span>
              <div class="txt">인터넷방송 / VOD / LIVE<br>음원 서비스 플랫폼 개발</div>
              <span class="more">
                <router-link :to="{ name:'Business' }">more view<i></i></router-link>
              </span>
            </div>

            <div class="main-business-conts">
              <span class="img"><img src="@/assets/images/contents/ico_business_02.png" alt="cryptocurrency"></span>
              <h3>가상화폐 거래소 개발</h3>
              <span class="info-txt">cryptocurrency exchange<br>development</span>
              <div class="txt">거래소 제작 및 컨설팅, 기술지원 및<br>유지보수</div>
              <span class="more">
                <router-link :to="{ name:'Business' }">more view<i></i></router-link>
              </span>
            </div>

            <div class="main-business-conts">
              <span class="img"><img src="@/assets/images/contents/ico_business_03.png" alt="cryptocurrency"></span>
              <h3>솔루션 제공 웹 / 앱</h3>
              <span class="info-txt">Web / App solution<br>developement</span>
              <div class="txt">홈페이지 제작 및 유지보수,<br>서버 호스팅 제공</div>
              <span class="more">
                <router-link :to="{ name:'Business' }">more view<i></i></router-link>
              </span>
            </div>

            <div class="main-business-conts">
              <span class="img"><img src="@/assets/images/contents/ico_business_04.png" alt="markeing"></span>
              <h3>마케팅 프로그램 개발</h3>
              <span class="info-txt">markeing program<br>developement</span>
              <div class="txt">바이럴 마케팅, 키워드 광고,<br>검색엔진 추출 관리 시스템 등</div>
              <span class="more">
                <router-link :to="{ name:'Business' }">more view<i></i></router-link>
              </span>
            </div>

            <div class="main-business-conts">
              <span class="img"><img src="@/assets/images/contents/ico_business_05.png" alt="solution"></span>
              <h3>HTS 솔루션 개발</h3>
              <span class="info-txt">HTS solution development</span>
              <div class="txt">전 증권사 API를 이용한<br>맞춤형 HTS 개발</div>
              <span class="more">
                <router-link :to="{ name:'Business' }">more view<i></i></router-link>
              </span>
            </div>

            <div class="main-business-conts">
              <span class="img"><img src="@/assets/images/contents/ico_business_06.png" alt="custom"></span>
              <h3>고객 주문형 솔루션 개발</h3>
              <span class="info-txt">Develop custom solutions</span>
              <div class="txt">인트라넷 / 그룹웨어, 자재관리 시스템,<br>모바일 게임 외 기타</div>
              <span class="more">
                <router-link :to="{ name:'Business' }">more view<i></i></router-link>
              </span>
            </div>

          </div>
        </div>

        <!-- main recruit -->
        <div class="main-section">
          <div class="main-recruit-area">
            <div class="main-title">
                <span>Softempire Recruit</span>
                <h2>채용 안내</h2>
            </div>

            <div class="main-recruit-conts">
              <span class="img"><img src="@/assets/images/contents/ico_recruit_01.png" alt="채용방식"></span>
              <h3>채용방식</h3>
              <div class="info-txt">부서별로 신규인력에 대한 소요 또는 결원 발생 시 수시채용을 하고 있으며, 특정 직무에 한해 충원이 필요한 경우 공개채용 방식을 진행하고 있습니다.</div>
            </div>

            <div class="main-recruit-conts">
              <span class="img"><img src="@/assets/images/contents/ico_recruit_02.png" alt="모집분야"></span>
              <h3>모집분야</h3>
              <ul class="info-list">
                <li>앱 개발자 (7년 이상 경력자, 네이티브 앱 및 플러터)</li>
                <li>프론트엔드 & 웹퍼블리셔 개발자 (5년 이상 경력자)</li>
              </ul>
            </div>

            <div class="main-recruit-conts">
              <span class="img"><img src="@/assets/images/contents/ico_recruit_03.png" alt="지원방법"></span>
              <h3>지원방법</h3>
              <div class="info-txt mb-40">상시채용은 당사의 홈페이지를 통해서 지원하실 수 있습니다.</div>
              <ul class="info-list type2">
                <li>문의 및 접수 : <span class="fc-gray-l">경영지원팀</span></li>
                <li><a href="tel:031-715-2100"><i class="ico-tel"></i>031-715-2100</a></li>
                <li><a href="mailto:help@softempire.co.kr"><i class="ico-mail"></i>help@softempire.co.kr</a></li>
              </ul>
            </div>

            <div class="main-recruit-conts">
              <span class="img"><img src="@/assets/images/contents/ico_recruit_04.png" alt="기타"></span>
              <h3>기타</h3>
              <ul class="info-list">
                <li>지원서 외 각종 서류는 최종 합격자에 한하여 제출합니다.</li>
                <li>입사 지원서 기재사항이 사실과 다른 경우에는 합격이 취소됩니다.</li>
                <li>국가보훈대상자는 관계법령에 의거 우대합니다.</li>
                <li>지원서 접수 후 채용관련 진행사항은 개별 통보해 드립니다.</li>
              </ul>
            </div>            

          </div>
        </div>

        <!-- main support -->
        <div class="main-section bg-type-2">
          <div class="main-support-area">
            <div class="main-title">
                <span>Customer Support</span>
                <h2>고객 지원</h2>
            </div>
            <div class="main-conts-box support">

              <div class="main-support-text">
                <p>소프트엠파이어는 맞춤형 소프트웨어개발 및 네트워크구축 서비스를 제공하는 기업입니다. 어떠한 상황에서도 고객이 만족하는 솔루션과 시스템 제공을 약속합니다.</p>
                <span>Soft Empire is a company that provides customized software development and network construction services.We promise to provide solutions and systems that satisfy our customers under any circumstances.</span>
              </div>

              <div class="main-support-conts">

                <div class="main-support-box">
                  <h3>공지사항</h3>
                  <h4>Softempire <span>Notice</span></h4>
                  <p>소프트엠파이어 홈페이지 공지사항 게시판 입니다.</p>
                  <router-link :to="{ name:'NoticeList' }">공지사항 바로가기<i></i></router-link>
                </div>

                <div class="main-support-box">
                  <h3>묻고답하기</h3>
                  <h4>Softempire <span>Q&amp;A</span></h4>
                  <p>간단한 문의사항은 이곳 Q&amp;A게시판을 이용하세요.</p>
                  <router-link :to="{ name:'QnaList' }">Q&amp;A 바로가기<i></i></router-link>
                </div>

                <div class="main-support-box">
                  <h3>이메일 문의</h3>
                  <h4>Contact us by <span>Email</span></h4>
                  <p>문의주신 내용은 담당자가 확인 후 답변 드립니다.</p>
                  <router-link :to="{ name:'OnlineEmail' }">이메일 문의 바로가기<i></i></router-link>
                </div>

                <div class="main-support-box">
                  <h3>모바일 문의</h3>
                  <h4>Contact us by <span>Mobile</span></h4>
                  <p>모바일로 문의하시면 빠른 답변을 해드립니다.</p>
                  <router-link :to="{ name:'OnlineMobile' }">모바일 문의 바로가기<i></i></router-link>
                </div>

              </div>

            </div>
          </div>
        </div>


      </div>
    <!-- //contents end -->

  </div>
  <!-- //container end -->

  <Footer/>

</template>

<script>
import { onMounted, ref } from 'vue';
//import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex';

import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  name:'HomeView',
  components:{
    Header,
    Footer,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {

    const store = useStore();
    //const route = useRoute();
    //const router = useRouter();
    store.commit('CHANGE_CURRENT_PAGE', 'Home');

    const bannerId = ref('slide-banner-')
    const slides = [
      { id: '1', title: 'Softempire Business', content: '소프트엠파이어만의<br>고객 만족 맞춤 서비스', text:'소프트엠파이어는 고객 만족을 위해<br>최적의 솔루션과 시스템을 제공할 수 있도록 최선을 다합니다' },
      { id: '2', title: 'Creativity', content: '혁신을 리드하는<br>업계 선두주자 소프트엠파이어', text:'소프트엠파이어는 급변하는 환경 속에서<br>업계와 시장을 리드합니다' },
      { id: '3', title: 'People', content: '사람 중심, 즐거운 기업문화<br>소프트엠파이어', text:'소프트엠파이어는 사람 중심의 기업으로<br>즐겁게 일하는 기업 문화를 만들어갑니다'},
    ]

    onMounted(()=> {
      // console.log('Home:', store.state.currentPage)
    })

    return {
      bannerId,
      slides,
    }
  }


}
</script>
