<template>

    <!-- footer start -->
    <div id="footer">
        <div class="foot-logo">
            <img src="@/assets/images/common/logo_foot.png" alt="softempire">
        </div>
        <div class="foot-info-area">
            <ul class="address">
                <li>(주)소프트엠파이어</li>
                <li>대표 : 장원석</li>
                <li>사업자등록번호 : 49386-00583</li>
                <li>경기도 성남시 분당구 성남대로 393 A715호 </li>
            </ul>
            <ul class="tel">
                <li>
                    <a href="tel:0317152100">
                        <i class="ico-tel"></i> : 031-715-2100
                    </a>
                </li>
                <li><i class="ico-fax"></i> : 031-715-2102</li>
                <li>
                    <a href="mailto:help@softempire.co.kr">
                        <i class="ico-mail"></i> : help@softempire.co.kr
                    </a>
                </li>
                <li>
                    <a href="javascript:;" @click="onModalOpen" v-if="!isLogin">관리자 로그인</a>
                    <a href="javascript:;" @click="onLogout" v-else>관리자 로그아웃</a>
                </li>
            </ul>
            <div class="copy">
                Copyright &copy; 2017.<span class="com">SOFTEMPIRE</span>All Right Reserved.
            </div>
        </div>
    </div>
    <!-- //footer end -->

    <AdminLoginModal
        v-if="showModal"
        @cancle="closeModal"
        @close="closeModal"
        @ok="closeModal"
        :titleTxt="txtTitle"
    />

</template>

<script>
import AdminLoginModal from '@/components/popups/AdminLoginModal.vue';
import { ref, computed, getCurrentInstance } from 'vue';
// import { ref, computed, getCurrentInstance, onMounted } from 'vue';
import { useApicall } from '@/composables/commonFn';
import { useStore } from 'vuex';
import VueCookies from "vue-cookies";

export default {
  name:'FooterComponent',
  components:{
        AdminLoginModal,
    },
    setup() {
        const showModal = ref(false);
        const txtTitle = ref('관리자로그인');
        const store = useStore();
        const { apiCall_g } = useApicall();
        const isLogin = computed(() => store.state.isLogin);
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

        if( VueCookies.get('isLoginCookie') ) { 
            store.commit('CHANGE_IS_LOGIN', true);
        } else {
            store.commit('CHANGE_IS_LOGIN', false);
        }

        const onModalOpen = () => {
            showModal.value = true;
            document.getElementById('modal').classList.add('admin-login');
        }

        const closeModal = () => {
            showModal.value = false;
        }        
        // console.log('footer', isLogin.value, VueCookies.get('isLoginCookie'))

        const onLogout = () => {
            emitter.emit('EVENT_CONFIRM',{ title:"알림", body:"관리자 로그아웃 하시겠습니까?", fn:logoutAdmin });
        }

        const logoutAdmin = async() => {
            var res = await apiCall_g(`/member/logout`);
            if(res.returnVal == 1) {
                if( VueCookies.get('isLoginCookie') ) { 
                    store.commit('CHANGE_IS_LOGIN', false);
                    VueCookies.remove('isLoginCookie', 'LoginCookie');
                }
            }
        }

        // onMounted(()=> {
        //     loginCheck();
        // })

        // const loginCheck = async()=> {
        //     var res = await apiCall_g(`/member/logincheck`);
        //     console.log('res:', res, res.member);
        //     console.log('getcookies', VueCookies.get('ch-veil-id'))
        // }

        return {
            showModal,
            onModalOpen,
            closeModal,
            txtTitle,
            isLogin,
            onLogout,
        }
    }

}
</script>
