<template>
  <Header/>

  <!-- container start -->
  <div id="container">

    <SubTitle />

    <!-- contents start -->
    <div id="contents">

      <!-- section -->
      <div class="sub-section">
        <div class="section">
          <div class="title-area">
            <div class="sec-title">
                <h3>인재상 <span>Our People</span></h3>
            </div>
            <div class="sec-title-txt">
              <p>소프트엠파이어는 당신을 기다립니다</p>
            </div>
          </div>
          <div class="conts-area people">
            <div class="conts-box">
              <div class="people-1">
                <h4>인성</h4>
                <p>올바른 생각과 가치관을<br>가진사람</p>
              </div>
              <span class="circle"></span>
              <div class="people-2">
                <h4>창조</h4>
                <p>새로운 것을 추구하고<br>도전정신을 가진사람</p>
              </div>
              <span class="circle"></span>
              <div class="people-3">
                <h4>혁신</h4>
                <p>변화를 두려워하지<br>않는 사람</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //section -->

      <!-- section -->
      <div class="sub-section bg-type-2">
        <div class="section benefit">
          <div class="title-area">
            <div class="sec-title">
                <h3>복리후생 <span>Benefits</span></h3>
            </div>
            <div class="sec-title-txt">
              <p>사람 중심, 즐거운 기업문화를 추구합니다</p>
            </div>
            <div class="sec-title-txt2"><span><strong>We're committed to a</strong> people-centered,</span> fun <strong>company</strong> culture</div>
          </div>
          <div class="conts-area">
            <div class="conts-box">

              <div class="benefit-box">
                <div class="title">
                  <span class="num">01</span>
                  <h4>복리후생</h4>
                </div>
                <ul>
                  <li>업무특성반영 자택근무가능</li>
                  <li>개인환경반영 출퇴근시간 조정가능</li>
                  <li>아침/점심/저녁 식대 지원</li>
                  <li>주유비,주차비 지원</li>
                  <li>자녀 출산/입학 교육비 지원</li>
                  <li>명절 상여금 지원</li>
                </ul>
              </div>

              <div class="benefit-box">
                <div class="title">
                  <span class="num">02</span>
                  <h4>휴가제도</h4>
                </div>
                <ul>
                  <li>기본제공 휴가</li>
                  <li>프로젝트완료 휴가</li>
                  <li>포상휴가</li>
                </ul>
              </div>

              <div class="benefit-box">
                <div class="title">
                  <span class="num">03</span>
                  <h4>문화제도</h4>
                </div>
                <ul>
                  <li>장기근속자 휴가 및 기념품 지급</li>
                  <li>문화생활 지원금 지급</li>
                  <li>경조금, 경조화환</li>
                  <li>매년 종합건강검진 지원</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- //section -->

      <!-- section -->
      <div class="sub-section">
        <div class="section career">
          <div class="title-area">
            <div class="sec-title">
                <h3>채용안내 <span>Careers</span></h3>
            </div>
            <div class="sec-title-txt">
              <span>회사에서 함께 할</span> <span>인재를 기다리고 있습니다</span>
              <p>열정이 있는 당신<br>소프트엠파이어와 함께 꿈을 이루세요.</p>
              <!-- <router-link :to="{ name:'RecruitingWrite' }" class="btn btn-primary"> -->
              <a href="javascript:;" class="btn btn-primary" @click="onClickRecruiting">
                  입사지원 바로가기 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_right_w.png" alt="입사지원"></i>
              </a>
            </div>
          </div>
          <div class="conts-area">
            <div class="conts-box">

              <div class="recruit-conts">
                <div class="img-area">
                  <span class="img"><img src="@/assets/images/contents/ico_recruit_01.png" alt="채용방식"></span>
                </div>
                <div class="txt-area">
                  <h3>채용방식</h3>
                  <div class="info-txt">부서별로 신규인력에 대한 소요 또는 결원 발생 시 수시채용을 하고 있으며, 특정 직무에 한해 충원이 필요한 경우 공개채용 방식을 진행하고 있습니다.</div>
                </div>
              </div>

              <div class="recruit-conts">
                <div class="img-area">
                  <span class="img"><img src="@/assets/images/contents/ico_recruit_02.png" alt="모집분야"></span>
                </div>
                <div class="txt-area">
                  <h3>모집분야</h3>
                  <ul class="info-list">
                    <li>앱 개발자 (7년 이상 경력자, 네이티브 앱 및 플러터)</li>
                    <li>프론트엔드 & 웹퍼블리셔 개발자 (5년 이상 경력자)</li>
                  </ul>
                </div>
              </div>

              <div class="recruit-conts">
                <div class="img-area">
                  <span class="img"><img src="@/assets/images/contents/ico_recruit_03.png" alt="지원방법"></span>
                </div>
                <div class="txt-area">
                  <h3>지원방법</h3>
                  <div class="info-txt">상시채용은 당사의 홈페이지를<br>통해서 지원하실 수 있습니다.</div>
                  <ul class="info-list type2">
                    <li>문의 및 접수 : <span class="fc-gray-l">경영지원팀</span></li>
                    <li><a href="tel:031-715-2100"><i class="ico-tel"></i>031-715-2100</a></li>
                    <li><a href="mailto:help@softempire.co.kr"><i class="ico-mail"></i>help@softempire.co.kr</a></li>
                  </ul>
                </div>
              </div>

              <div class="recruit-conts">
                <div class="img-area">
                  <span class="img"><img src="@/assets/images/contents/ico_recruit_04.png" alt="기타"></span>
                </div>
                <div class="txt-area">
                  <h3>기타</h3>
                  <ul class="info-list">
                    <li>지원서 외 각종 서류는 최종 합격자에 한하여 제출합니다.</li>
                    <li>입사 지원서 기재사항이 사실과 다른 경우에는 합격이 취소됩니다.</li>
                    <li>국가보훈대상자는 관계법령에 의거 우대합니다.</li>
                    <li>지원서 접수 후 채용관련 진행사항은 개별 통보해 드립니다.</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- //section -->

    </div>
    <!-- //contents end -->
  </div>
  <!-- //container end -->

  <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import { getCurrentInstance } from 'vue';

export default {
  name: 'RecruitingView',
  components:{
    Header,
    Footer,
    SubTitle,    
  },    
  setup() {
    const store = useStore();
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    store.commit('CHANGE_CURRENT_PAGE', 'Recruiting');
    // console.log('Recruiting:', store.state.currentPage)
    
    
    const onClickRecruiting = () => {
      emitter.emit('EVENT_ALERT',{title:"알림", body:"help@softempire.co.kr 이메일로 지원 부탁드립니다."});
    }

    return {
      onClickRecruiting
    }
  }    
}
</script>
