<template>
 <Header/>

<!-- container start -->
<div id="container">

    <SubTitle />

    <!-- contents start -->
    <div id="contents">

        <!-- section -->
        <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                    <div class="sec-title">
                        <h3>묻고답하기 <span>Questions and answers</span></h3>
                    </div>
                    <div class="sec-title-txt">
                        <p>Q&amp;A</p>
                    </div>
                </div>
            </div>

            <div class="section online">
                <div class="conts-area">
                    <div class="conts-box">
                        <form>
                            <div class="form-box">
                                <label for="uName">작성자<i class="im">&#42;</i></label>
                                <input type="text" id="uName" ref="uName" maxlength="10" placeholder="이름을 입력해 주세요.">
                            </div>
                            <div class="form-box support-write-pw">
                                <div class="inner-pw">
                                    <label for="uPw">비밀번호<i class="im">&#42;</i></label>
                                    <span class="chk">
                                        <input type="checkbox" id="chk1" checked="checked" v-model="chk1" disabled>
                                        <label for="chk1">비밀글</label>
                                    </span>
                                </div>
                                <input type="password" id="uPw" ref="uPw" maxlength="6" v-model="inputPasswdNum" placeholder="비밀번호를 입력해 주세요.(최대 6자 숫자만 입력 가능합니다.)" autocomplete="" @input="inputPasswdNum = $event.target.value.replace(/[^0-9]/g, '')">
                            </div>
                            <div class="form-box">
                                <label for="qnaSubject">제목<i class="im">&#42;</i></label>
                                <input type="text" id="qnaSubject" ref="qnaSubject" placeholder="제목을 입력해 주세요.">
                            </div>
                            <div class="form-box">
                                <label for="qnaContent">내용<i class="im">&#42;</i></label>
                                <textarea id="qnaContent" ref="qnaContent" placeholder="내용을 입력해 주세요."></textarea>
                            </div>
                            <div class="file-box write-file">
                                <label>파일첨부 <span class="fs14 fc-gray">(이미지: jpg, png, gif, 최대용량 10MB)</span></label>
                                <input type="file" id="inFile" name="inFile" ref="qnaFile" accept=".jpg,.jpeg,.png,.gif" @change="onChangeFile($event)">
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="btn-area d-flex-center">
                <button type="button" title="작성완료" class="btn btn-primary" @click="onComplated">
                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_check_w.png" alt="작성완료"></i>작성완료
                </button>
                <router-link :to="{ name:'QnaList' }" class="btn btn-outline-w">
                <i class="btn-ico-l"><img src="@/assets/images/contents/ico_cancel.png" alt="취소"></i>취소
                </router-link>
            </div>

        </div>
        <!-- //section -->

    </div>
    <!-- //contents end -->
</div>
<!-- //container end -->

<Footer/>
</template>

<script>
import { useStore } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import { useApicall } from '@/composables/commonFn';
import { useRoute, useRouter }from 'vue-router'
import { ref, getCurrentInstance, onMounted } from 'vue';
import axios from '/axios';
import Config from '/public/config.js'

export default {
    name: 'QnaWrite',
    components:{
        Header,
        Footer,
        SubTitle,    
    },    
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();        
        const { apiCall_g } = useApicall();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const uName = ref('');
        const chk1 = ref(true);
        const uPw = ref(null);
        const qnaSubject = ref('');
        const qnaContent = ref('');
        const qnaFile = ref(null);
        const qnaFileTarget = ref(null);
        const qnaFileData = ref(null);
        const qnaPasswd = ref(null);
        const inputPasswdNum = ref(null);
        const boardCategory = ref('qna');
        const boardItem = ref({});

        store.commit('CHANGE_CURRENT_PAGE', 'Support');
        // console.log('Recruiting:', store.state.currentPage)

        //mode = 1:쓰기 2:수정
        var mode = 1;
        
        onMounted( async() => {
            // console.log('mode', mode)
            if(route.params.idx) {
                mode = 2;
                qnaPasswd.value = store.state.qnaCurrentPasswd;
                inputPasswdNum.value = qnaPasswd.value;
                boardItem.value = await getModifyBoardItem(route.params.idx, qnaPasswd.value);
                uName.value.value = boardItem.value.NAME;                
                qnaSubject.value.value = boardItem.value.SUBJECT;
                qnaContent.value.value = boardItem.value.CONTENTS;
                uName.value.readOnly = true;

                if(boardItem.value.ISFILE == 'Y') addModifyInputFile();
                // console.log('onMounted', boardItem.value, boardItem.value.NAME)
            } else {
                mode = 1;
            }
        });

        const onComplated = async() => {
            const qnaIsScret = chk1.value == true ? 'Y': 'N';
            // console.log('onComplated', '작성완료:', uName.value.value, chk1.value, uPw.value.value, qnaSubject.value.value, qnaContent.value.value, qnaFileData.value, qnaIsScret);            

            if( boardValidation() ) {
                var formData = new FormData();
                var requestUrl = '';
            
                if(mode == 1) {
                    formData.append('category', boardCategory.value);
                    formData.append('name', uName.value.value);
                    formData.append('isScret', qnaIsScret);
                    
                    if(qnaFileData.value) formData.append('upfile', qnaFileData.value);
                    requestUrl = '/board/counselingwrite';
                } else {
                    if(qnaFile.value.files[0]) {
                        if(qnaFile.value.files[0].name !== boardItem.value.FILE_NAME) {
                            formData.append('upfile', qnaFile.value.files[0]);
                        }
                    }
                    // console.log(qnaFile.value.files[0].name,qnaFileData.value.name)
                    formData.append('idx', route.params.idx);
                    requestUrl = '/board/counselingmodify';            
                }

                formData.append('subject', qnaSubject.value.value);
                formData.append('contents', qnaContent.value.value);
                formData.append('passwd', uPw.value.value);

                axios.post(requestUrl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then(function(response) {
                    let res = response.data;
                    // console.log("json: ", res);

                    if (res.returnCode == 1) {
                        if (res.resultMap.returnVal == 1) {
                            // console.log("sueccess:");
                            emitter.emit('EVENT_ALERT',{ title:"알림", body:"문의 등록이 완료되었습니다." });
                            router.push({
                                name: 'QnaList'
                            })
                            resetFormFeild();
                        } else {
                            // console.log(res.message);
                            emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        }
                    } else {
                        emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        // console.log(res.message);
                    }
                })
                .catch(function(error) {
                    console.log(error)
                });

            }
        }

        const boardValidation = () => {
            const addWarningText = (field, fieldName) => {
                var warningText = field.parentNode.querySelector('.warning-txt');
                if(warningText) return false;
                // console.log(warningText)

                const warningElement = document.createElement('span');
                warningElement.classList.add('warning-txt');
                warningElement.textContent = `* ${fieldName} 입력해주세요.`;
                field.parentNode.appendChild(warningElement);
            };

            const removeWarningText = () => {
                // const warningText = field.parentNode.querySelector('.warning-txt');
                // if (warningText) {
                //     field.parentNode.removeChild(warningText);
                // }
                const warningText = document.querySelectorAll('.warning-txt');
                const warningClass = document.querySelectorAll('.warning');
                warningText.forEach(txt => txt.remove());
                warningClass.forEach(cl => cl.classList.remove('warning'));

            };

            const validateField = (field, fieldName) => {
                removeWarningText();

                if (!field.value || !field.value.trim()) {
                    addWarningText(field, fieldName);
                    field.classList.add('warning');
                    field.focus();
                    return false;
                } else {
                    return true;
                }
            };

            if (!validateField(uName.value, "작성자를")) {
                return false;
            }
            if (!validateField(uPw.value, "비밀번호를")) {
                return false;
            }
            if (!validateField(qnaSubject.value, "문의 제목을")) {
                return false;
            }
            if (!validateField(qnaContent.value, "문의 내용을")) {
                return false;
            }

            return true;
        };

        const onChangeFile = (e) => {
            // console.log('changeFile:', e, e.target.files[0]);
            qnaFileData.value = '';

            var extension = ['jpg', 'png', 'gif'];
            var maxSize = 10 * 1024 * 1024;
            
            if(e.target.value !== '') {
                var fileName = e.target.files[0].name;
                var fileSize = e.target.files[0].size;

                var ext = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();

                if (extension.includes(ext)) {
                    if (fileSize > maxSize) {
                        emitter.emit('EVENT_ALERT',{ title:"알림", body:"파일의 최대 용량을 초과하였습니다." });
                        e.target.value = '';
                        e.target.style.color = '#343434';
                        return false;
                    }
                } else {
                    emitter.emit('EVENT_ALERT',{ title:"알림", body:"추가 가능한 파일이 아닙니다." });
                    e.target.value = '';
                    e.target.style.color = '#343434';
                    return false;
                }

                qnaFileTarget.value = e.target;
                qnaFileData.value = e.target.files[0];
                e.target.style.color = '#fff';

            } else {
                e.target.style.color = '#343434';
                qnaFileData.value = '';
                return false;
            }

            return true;
        }

        const resetFormFeild = () => {
            boardCategory.value = '';
            uName.value.value = '';
            qnaSubject.value.value = '';
            qnaContent.value.value = '';
            uPw.value.value = '';
            if(qnaFileTarget.value) {
                qnaFileTarget.value.value = '';
                qnaFileData.value = '';
            }

            // qnaFileTarget.value.style.color = '#343434';
        }

        const getModifyBoardItem = async( idx, passwd ) => {   
            const res = await apiCall_g(`/board/counselingview?idx=${idx}&passwd=${passwd}`);
            return res.data;
        }

        const addModifyInputFile = () => {
            var fileInput = qnaFile.value;
            var fileInputData =`${Config.URL.softEmpireUrl}${boardItem.value.FILE_DIR}/${boardItem.value.FILE_REAL_NAME}`

            const myFile = new File([fileInputData], boardItem.value.FILE_NAME, {
                type: 'image/png',
                lastModified: new Date(),
            });

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(myFile);
            fileInput.files = dataTransfer.files;
            fileInput.style.color = '#fff';

            if (fileInput.webkitEntries.length) {
                fileInput.dataset.file = `${dataTransfer.files[0].name}`;
            }

            // console.log(fileInput.files[0]);
        }

        return {
            onComplated,
            uName,
            chk1,
            uPw,
            qnaSubject,
            qnaContent,
            qnaFile,
            qnaFileData,
            inputPasswdNum,
            onChangeFile,
            boardCategory,
            qnaFileTarget,
            qnaPasswd,
            boardItem,
        }
    }    
}
</script>
