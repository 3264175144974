<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

            <!-- section -->
            <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                    <div class="sec-title">
                        <h3>온라인 문의 <span>Contact us by online</span></h3>
                    </div>
                    <div class="sec-title-txt">
                        <p>고객 정보와 문의내용을 입력해주세요</p>
                    </div>
                </div>

                <!-- tab area -->
                <div class="sec-tab-area">
                    <ul>
                        <li v-if="isLogin"><a href="javascript:;" @click="onlineListGo">문의리스트</a></li>
                        <li><a href="javascript:;" class="on">이메일문의</a></li>
                        <li><router-link :to="{ name: 'OnlineMobile' }">모바일문의</router-link></li>
                    </ul>
                </div>
            </div>

            <div class="section online">
                <div class="conts-area">
                    <div class="conts-box">
                        <form>
                            <div class="form-box">
                                <label for="uName">이름<i class="im">&#42;</i></label>
                                <input type="text" id="uName" ref="uName" placeholder="이름을 입력해 주세요.">
                            </div>
                            <div class="form-col">
                                <p class="form-col-tit">연락처</p>
                                <div class="col">
                                    <div class="form-select">
                                        <label for="tNum1" class="blind">연락처</label>
                                        <select id="tNum1" ref="tNum1">
                                            <option value="00" selected="selected">번호 선택</option>
                                            <option value="02">02</option>
                                            <option value="031">031</option>
                                            <option value="032">032</option>
                                            <option value="033">033</option>
                                            <option value="041">041</option>
                                            <option value="042">042</option>
                                            <option value="043">043</option>
                                            <option value="051">051</option>
                                            <option value="052">052</option>
                                            <option value="053">053</option>
                                            <option value="054">054</option>
                                            <option value="055">055</option>
                                            <option value="061">061</option>
                                            <option value="062">062</option>
                                            <option value="063">063</option>
                                            <option value="064">064</option>
                                            <option value="070">070</option>
                                        </select>
                                    </div>  
                                    <span class="unit">-</span>
                                    <div class="form-box">
                                        <label for="tNum2" class="blind">번호</label>
                                        <input type="tel" id="tNum2" ref="tNum2" maxlength="4" v-model="inputTnum2" @input="inputTnum2 = $event.target.value.replace(/[^0-9]/g, '')">
                                    </div>
                                    <span class="unit">-</span>
                                    <div class="form-box">
                                        <label for="tNum3" class="blind">번호</label>
                                        <input type="tel" id="tNum3" ref="tNum3" maxlength="4" v-model="inputTnum3" @input="inputTnum3 = $event.target.value.replace(/[^0-9]/g, '')">
                                    </div>
                                </div>
                            </div>
                            <div class="form-col">
                                <p class="form-col-tit">휴대폰</p>
                                <div class="col">
                                    <div class="form-select">
                                    <label for="pNum1" class="blind">휴대폰</label>
                                    <select id="pNum1" ref="pNum1">
                                        <option value="" selected="selected">번호선택</option>
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                    </select>
                                    </div>  
                                    <span class="unit">-</span>
                                    <div class="form-box">
                                        <label for="pNum2" class="blind">번호</label>
                                        <input type="tel" id="pNum2" ref="pNum2" maxlength="4" v-model="inputPnum2" @input="inputPnum2 = $event.target.value.replace(/[^0-9]/g, '')">
                                    </div>
                                    <span class="unit">-</span>
                                    <div class="form-box">
                                        <label for="pNum3" class="blind">번호</label>
                                        <input type="tel" id="pNum3" ref="pNum3" maxlength="4" v-model="inputPnum3" @input="inputPnum3 = $event.target.value.replace(/[^0-9]/g, '')">
                                    </div>
                                </div>
                            </div>
                            <div class="form-col">
                                <p class="form-col-tit">이메일<i class="im">&#42;</i></p>
                                <div class="col-email">
                                    <div class="col">
                                    <div class="form-box mail">
                                        <label for="email1" class="blind">메일</label>
                                        <input type="email" id="email1" ref="email1">
                                    </div>
                                    <span class="unit">@</span>
                                    <div class="form-box mail">
                                        <label for="email2" class="blind">메일</label>
                                        <input type="email" id="email2" ref="email2">
                                    </div>
                                    </div>
                                    <div class="form-select mail-s">
                                    <label for="email3" class="blind">이메일</label>
                                    <select id="email3" ref="email3" @change="onChangeEmailSelect">
                                        <option value="" selected="selected">직접입력</option>
                                        <option value="gmail.com">gmail.com</option>
                                        <option value="naver.com">naver.com</option>
                                        <option value="daum.net">daum.net(hanmail.net)</option>
                                        <option value="kakao.com">kakao.com</option>
                                        <option value="yahoo.com">yahoo.com</option>
                                        <option value="microsoft.com">microsoft.com</option>
                                    </select>
                                    </div>
                                </div>            
                            </div>
                            <div class="form-box">
                                <label for="eSubject">제목<i class="im">&#42;</i></label>
                                <input type="text" id="eSubject" ref="eSubject" placeholder="제목을 입력해 주세요.">
                            </div>
                            <div class="form-box">
                                <label for="eContent">내용<i class="im">&#42;</i></label>
                                <textarea id="eContent" ref="eContent" placeholder="내용을 입력해 주세요."></textarea>
                            </div>
                            <div class="file-box write-file">
                                <label>파일첨부 <span class="fs14 fc-gray">(이미지: jpg, png, gif, 최대용량 10MB)</span></label>
                                <input type="file" id="inFile" name="inFile" ref="emailFile" accept=".jpg,.jpeg,.png,.gif" @change="onChangeFile($event)">
                            </div>
                        </form>
                    </div>

                </div>
            </div>

            <div class="section privacy">
                <div class="conts-box">
                    <p class="title">개인정보 수집 및 이용에 대한 안내</p>
                    <p class="txt">(주)소프트엠파이어에서는 기업/단체 및 개인의 정보 수집 및 이용 등 처리에 있어 아래의 사항을 관계법령에 따라 고지하고 안내해 드립니다.</p>
                    <ol class="list">
                        <li>정보수집의 이용 목적 : 상담 및 진행</li>
                        <li>수집/이용 항목 : 이름, 일반전화, 휴대전화, 이메일, 상담내용</li>
                        <li>보유 및 이용기간 : 상담 종료후 6개월, 정보제공자의 삭제 요청시 즉시</li>
                        <li>개인정보처리담당 : 전화 031-715-2100 / 이메일 help@softempire.co.kr</li>
                    </ol>
                    <div class="checkbox-area">
                        <div>
                        <input type="checkbox" id="chk1" ref="privacyChk">
                        <label for="chk1">개인정보 수집 및 이용에 동의합니다.</label>
                        </div>
                        <span class="txt-pop" @click="onModalOpen">내용보기</span>
                    </div>
                </div>
                
                <div class="btn-area d-flex-center">
                    <button type="button" title="문의하기" class="btn btn-primary" @click="onComplated">
                        문의하기 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_right_bg.png" alt="arrow"></i>
                    </button>
                </div>
            </div>        

            </div>
            <!-- //section -->

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>

    <PrivacyModal
        v-if="showModal"
        @close="closeModal" 
        @ok="okModal" 
        :titleTxt="txtTitle_alert"
    />

</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, getCurrentInstance, computed } from 'vue';
import { useRouter }from 'vue-router'
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import PrivacyModal from '@/components/popups/PrivacyModal.vue';
import axios from '/axios';

export default {
    name: 'OnlineEmail',
    components:{
        Header,
        Footer,
        SubTitle,
        PrivacyModal,
    }, 
    setup() {
        const store = useStore();
        const router = useRouter();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const boardCategory = ref('email');
        const uName = ref('');
        const tNum1 = ref('');
        const tNum2 = ref('');
        const tNum3 = ref('');
        const pNum1 = ref('');
        const pNum2 = ref('');
        const pNum3 = ref('');
        const email1 = ref('');
        const email2 = ref('');
        const email3 = ref('');
        const eSubject = ref('');
        const eContent = ref('');
        const privacyChk = ref(false)
        const emailFile = ref(null);
        const emailFileTarget = ref(null);
        const emailFileData = ref(null);
        const inputTnum2 = ref(null);
        const inputTnum3 = ref(null);
        const inputPnum2 = ref(null);
        const inputPnum3 = ref(null);
        const isLogin = computed(() => store.state.isLogin);

        store.commit('CHANGE_CURRENT_PAGE', 'Online');

        onMounted(() => {
            // console.log('Online:', isLogin.value)
        });


        const onComplated = async() => {
            // console.log('onComplated', '작성완료:', uName.value.value, chk1.value, uPw.value.value, qnaSubject.value.value, qnaContent.value.value, qnaFileData.value, qnaIsScret);            

            if( boardValidation() ) {
                var formData = new FormData();
                            
                formData.append('category', boardCategory.value);
                formData.append('name', uName.value.value);
                formData.append('subject', eSubject.value.value);
                formData.append('contents', eContent.value.value);
                formData.append('email', `${email1.value.value}@${email2.value.value}`);
                
                if(tNum1.value.value !== '' && tNum2.value.value !== '' && tNum3.value.value !== '' ) {
                    formData.append('tel', `${tNum1.value.value}-${tNum2.value.value}-${tNum3.value.value}`);
                }

                if(pNum1.value.value !== '' && pNum2.value.value !== '' && pNum3.value.value !== '' ) {
                    formData.append('phone', `${pNum1.value.value}-${pNum2.value.value}-${pNum3.value.value}`);
                }

                if(emailFileData.value) formData.append('upfile', emailFileData.value);

                // console.log('onComplated1:', formData)
                // console.log('onComplated1:', boardCategory.value, uName.value.value, eSubject.value.value, eContent.value.value)
                // console.log('onComplated2:', `${tNum1.value.value}-${tNum2.value.value}-${tNum3.value.value}`, `${pNum1.value.value}-${pNum2.value.value}-${pNum3.value.value}`)
                // console.log('onComplated3:', `${email1.value.value}@${email2.value.value}`, emailFileData.value)

                axios.post('/board/counselingwrite', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then(function(response) {
                    let res = response.data;
                    // console.log("json: ", res);

                    if (res.returnCode == 1) {
                        if (res.resultMap.returnVal == 1) {
                            emitter.emit('EVENT_ALERT',{ title:"알림", body:"문의 등록 완료되었습니다." });
                            router.push({
                                name: 'OnlineEmail'
                            })
                            resetFormFeild();
                        } else {
                            emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        }
                    } else {
                        emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                    }
                })
                .catch(function(error) {
                    console.log(error)
                });

            }
        }

        const boardValidation = () => {
            const addWarningText = (field, fieldName) => {
                var warningText = field.parentNode.querySelector('.warning-txt');
                if(warningText) return false;

                const warningElement = document.createElement('span');
                warningElement.classList.add('warning-txt');
                warningElement.textContent = `* ${fieldName} 입력해주세요.`;
                field.parentNode.appendChild(warningElement);
            };

            const removeWarningText = () => {
                const warningText = document.querySelectorAll('.warning-txt');
                const warningClass = document.querySelectorAll('.warning');
                warningText.forEach(txt => txt.remove());
                warningClass.forEach(cl => cl.classList.remove('warning'));
            };

            const validateField = (field, fieldName) => {
                removeWarningText();

                if (!field.value || !field.value.trim()) {
                    addWarningText(field, fieldName);
                    field.classList.add('warning');
                    field.focus();
                    return false;
                } else {
                    return true;
                }
            };

            if (!validateField(uName.value, "이름을")) {
                return false;
            }
            if (!validateField(email1.value, "이메일을")) {
                return false;
            }
            if (!validateField(email2.value, "이메일을")) {
                return false;
            }
            if (!emailCheck(email2.value.value)) {
                return false;
            }

            if (!validateField(eSubject.value, "제목을")) {
                return false;
            }
            if (!validateField(eContent.value, "내용을")) {
                return false;
            }
            if (privacyChk.value.checked == false) {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"개인정보 수집 및 이용에 동의하여 주세요." });
                document.querySelector('.checkbox-area').classList.add('warning');
                privacyChk.value.focus();
                return false;
            }

            return true;
        };

        const onChangeFile = (e) => {
            // console.log('changeFile:', e, e.target.files[0]);
            emailFileData.value = '';

            var extension = ['jpg', 'png', 'gif'];
            var maxSize = 10 * 1024 * 1024;
            
            if(e.target.value !== '') {
                var fileName = e.target.files[0].name;
                var fileSize = e.target.files[0].size;

                var ext = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();

                if (extension.includes(ext)) {
                    if (fileSize > maxSize) {
                        emitter.emit('EVENT_ALERT',{ title:"알림", body:"파일의 최대 용량을 초과하였습니다." });
                        e.target.value = '';
                        e.target.style.color = '#343434';
                        return false;
                    }
                } else {
                    emitter.emit('EVENT_ALERT',{ title:"알림", body:"추가 가능한 파일이 아닙니다." });
                    e.target.value = '';
                    e.target.style.color = '#343434';
                    return false;
                }

                emailFileTarget.value = e.target;
                emailFileData.value = e.target.files[0];
                e.target.style.color = '#fff';

            } else {
                e.target.style.color = '#343434';
                emailFileData.value = '';
                return false;
            }

            return true;
        }

        const resetFormFeild = () => {
            uName.value.value = '';
            tNum1.value.value = '';
            tNum2.value.value = '';
            tNum3.value.value = '';
            pNum1.value.value = '';
            pNum2.value.value = '';
            pNum3.value.value = '';
            email1.value.value = '';
            email2.value.value = '';
            email3.value.value = '';
            eSubject.value.value = '';
            eContent.value.value = '';
            privacyChk.value.checked = false;

            if(emailFileTarget.value) {
                emailFileTarget.value.value = '';
                emailFileData.value = '';
            }
            // qnaFileTarget.value.style.color = '#343434';
        }

        const emailCheck = (address) => {
            var email_regex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

            if(!email_regex.test(address)){ 
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"이메일 형식을 확인하여 주십시오." });
                return false;
            }else{
                return true;
            }
        }

        const onChangeEmailSelect = () => {
            // console.log('onChangeEmail:', email3.value.value)

            if(email3.value.value == '') {
                email2.value.value = '';
                email2.value.disabled = false;
            } else {
                email2.value.value = email3.value.value;
                email2.value.disabled = 'disabled';
            }
        }



        const showModal = ref(false);
        const txtTitle_alert = ref('개인정보취급방침');

        const onModalOpen = () => {
            showModal.value = true;
            document.body.classList.add('scr-none');
        }   

        const okModal = () => {
            showModal.value = false;
            document.body.classList.remove('scr-none');
        }   

        const closeModal = () => {
            showModal.value = false;
            document.body.classList.remove('scr-none');
        }

        const onlineListGo = () => {
            if(isLogin.value) {
                router.push({
                    name:'OnlineList'
                });                
            }
        }

        return {
            showModal,
            okModal,
            closeModal,
            txtTitle_alert,
            onModalOpen,
            boardCategory,
            uName,
            tNum1,
            tNum2,
            tNum3,
            pNum1,
            pNum2,
            pNum3,
            email1,
            email2,
            email3,
            eSubject,
            eContent,
            emailFile,
            privacyChk,
            inputTnum2,
            inputTnum3,
            inputPnum2,
            inputPnum3,
            onComplated,
            boardValidation,
            onChangeFile,
            emailFileTarget,
            emailFileData,
            onChangeEmailSelect,
            isLogin,
            onlineListGo,
        }
    }    
}
</script>
