<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

            <!-- section -->
            <div class="sub-section bg-type-2">
                <div class="section">
                    <div class="title-area">
                        <div class="sec-title">
                            <h3>묻고답하기 <span>Questions and answers</span></h3>
                        </div>
                        <div class="sec-title-txt">
                            <p>Q&amp;A</p>
                        </div>
                    </div>

                    <!-- tab area -->
                    <div class="sec-tab-area">
                        <ul>
                            <li><router-link :to="{ name: 'NoticeList' }" >공지사항</router-link></li>
                            <li><a href="javascript:;" class="on">Q&amp;A</a></li>
                        </ul>
                    </div>
                </div>


                <div class="section support">

                    <div class="conts-area">
                        <div class="conts-box">
                            
                            <table class="tbl qna">
                                <thead>
                                    <tr>
                                        <th scope="col">번호</th>
                                        <th scope="col">제목</th>
                                        <th scope="col">작성자</th>
                                        <th scope="col">답변</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in boardList"
                                        :key = index                                
                                    >
                                        <td scope="row">{{ item.IDX }}</td>
                                        <td class="text-left" v-if="!isLogin">
                                            <a href="javascript:;" @click="onModalOpen(item.IDX)">
                                                <span class="ico" v-if="item.ISSCRET == 'Y'"><img src="@/assets/images/contents/ico_lock.png" alt="lock"></span>
                                                <span class="subject ellipsis">{{ item.SUBJECT }}</span>
                                                <span class="date">{{ item.CREATIONDATE.split(' ')[0].replaceAll('-','.') }} <span class="write">{{ item.ISSCRET ? ' / 비밀글입니다.': ' / 일반글입니다' }}</span></span>
                                            </a>
                                        </td>
                                        <td class="text-left" v-else>
                                            <router-link :to="{ name:'QnaView', params: {idx:item.IDX} }">
                                                <span class="ico" v-if="item.ISSCRET == 'Y'"><img src="@/assets/images/contents/ico_lock.png" alt="lock"></span>
                                                <span class="subject ellipsis">{{ item.SUBJECT }}</span>
                                                <span class="date">{{ item.CREATIONDATE.split(' ')[0].replaceAll('-','.') }} <span class="write">{{ item.ISSCRET ? ' / 비밀글입니다.': ' / 일반글입니다' }}</span></span>
                                            </router-link>
                                        </td>
                                        <td>{{ item.NAME }}</td>
                                        <td>
                                            <span class="hold" v-if="item.REPLY_OK == 'N' ">답변대기</span>
                                            <span class="complate" v-else>답변완료</span>
                                        </td>
                                    </tr>               
                                </tbody>
                            </table>


                            <div class="btn-area">
                                <!-- <button type="button" title="수정" class="btn btn-warning" @click="checkSession">
                                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_option.png" alt="확인"></i>확인
                                </button> -->
                                <router-link :to="{ name:'QnaWrite' }" class="btn btn-primary">
                                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_write.png" alt="문의하기"></i>문의하기
                                </router-link>            
                            </div>

                            <!-- paging start -->
                            <div class="paging" v-if="boardList.length">
                                <v-pagination
                                    v-model="currentPage"
                                    :pages=numberOfPages
                                    :rangeSize="1"
                                    activeColor="#436CFF"
                                    @update:modelValue="updateHandler(currentPage, categoryValue)"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>


    <QnaPasswdModal
        v-if="showModal"
        @cancle="closeModal"
        @close="closeModal" 
        @ok="closeModal"
        :titleTxt="txtTitle"
        :idx="qnaListIdx"
    />


</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, computed, onUnmounted } from 'vue';
import { useApicall } from '@/composables/commonFn';
// import {useRouter }from 'vue-router';
// import { useRoute }from 'vue-router'
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import VPagination from "@hennge/vue3-pagination";
import QnaPasswdModal from '@/components/popups/QnaPasswdModal.vue';


export default {
    name: 'QnaList',
    components:{
        Header,
        Footer,
        SubTitle,
        VPagination,
        QnaPasswdModal
    },
    setup() {
        const store = useStore();
        const { apiCall_g } = useApicall();
        const boardList = ref([]);
        const limit = ref(10);
        const currentPage = ref(1);
        const numberOfData = ref(0);
        const numberOfPages = computed(() => {
            return Math.ceil(numberOfData.value/limit.value)
        });
        const categoryValue = ref('qna');
        const showModal = ref(false);
        const txtTitle = ref('비밀번호 입력')
        const qnaListIdx = ref('');
        const page_store = computed(() => store.state.currentPage);
        const currentPage_store = computed(() => store.state.currentQnaPage);
        const isLogin = computed(() => store.state.isLogin);
        
        store.commit('CHANGE_CURRENT_PAGE', 'Support');

        onMounted(()=> {
            updateHandler(currentPage_store.value, categoryValue.value);
        })

        const updateHandler = (e,  _cVal) => {
            getDataList(e, _cVal);
        }

        const getDataList = async ( page = currentPage.value, categoryValue ) => {
            // var token = sessionStorage.getItem('token');  

            currentPage.value = page;
            store.commit('CHANGE_NOTICE_CURRENT_PAGE', page);

            var qnaApiUrl ='';
            isLogin.value == false ? qnaApiUrl = '/board' : qnaApiUrl = '/admin';

            var offset = (currentPage.value - 1) * limit.value;
            var getUrl = `${qnaApiUrl}/counselinglist?limit=${limit.value}&offset=${offset}&category=${categoryValue}`;

            var boardDataList = await apiCall_g(getUrl);

            try {
                if(boardDataList.dataList == null) boardDataList.dataList = [];
                boardList.value = boardDataList.dataList;
                numberOfData.value = boardDataList.dataCount;

                // console.log(boardList.value)
                
            } catch (error) {
                boardList.value = [];
                numberOfData.value  = 0;
            }
        }    

        onUnmounted(()=> {     
            if(page_store.value !== 'Support') {
                store.commit('CHANGE_QNA_CURRENT_PAGE', 1);
            }
        })

        const onModalOpen = (idx) => {
            qnaListIdx.value = '';
            showModal.value = true;
            qnaListIdx.value = idx;
            document.getElementById('modal').classList.add('qna-passwd');
            // console.log(document.querySelector('#modal #inputPw'))
            // document.getElementById('inputPw').focus();
        }

        const closeModal = () => {
            showModal.value = false;
            document.body.classList.remove('scr-none');
            document.getElementById('modal').classList.remove('qna-passwd');
            // if(inputPasswdNum.value) inputPasswdNum.value = '';
        }

        return {
            boardList,
            currentPage,
            categoryValue,
            numberOfPages,
            updateHandler,
            page_store,
            txtTitle,
            showModal,
            onModalOpen,
            closeModal,
            qnaListIdx,
            isLogin,
        }
    }     
}
</script>
