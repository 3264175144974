
<template>

    <teleport to="#modal">
        <div class="modal-wrapper">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <slot name="title"></slot>
                        </h5>
                        <button type="button" class="close" @click="onClose">&times;</button>
                    </div>
                    <div class="modal-body">                  
                        <slot name="body"></slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </teleport>    


</template>

<script>
import { getCurrentInstance } from 'vue';

export default {  
   emits:['close'],
   setup() {
    const { emit } = getCurrentInstance();
    const onClose = () => {
        emit('close');
        // console.log('onClose :', 'onclose', emit)
    }
     return {
         onClose,
     }
   }

}
</script>
