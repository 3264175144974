<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">
            <!-- section -->
            <div class="sub-section bg-type-2">
                <div class="section">
                    <div class="title-area">
                        <div class="sec-title">
                            <h3>온라인 문의 <span>Contact us by online</span></h3>
                        </div>
                        <div class="sec-title-txt">
                            <p>고객 정보와 문의내용을 입력해주세요</p>
                        </div>
                    </div>

                    <!-- tab area -->
                    <div class="sec-tab-area">
                        <ul>
                            <li v-if="isLogin"><a href="javascript:;" class="on">문의리스트</a></li>
                            <li><router-link :to="{ name: 'OnlineEmail' }" >이메일문의</router-link></li>
                            <li><router-link :to="{ name: 'OnlineMobile' }">모바일문의</router-link></li>
                        </ul>
                        <div class="tab-category-area">
                            <ul>
                                <li><a href="javascript:;" id="email" @click="onClickCategory">이메일문의</a></li>
                                <li><a href="javascript:;" id="mobile" @click="onClickCategory">모바일문의</a></li>
                            </ul>
                        </div>                        
                    </div>
                </div>

                <div class="section support">

                    <div class="conts-area">
                        <div class="search">
                            <div class="form-area">
                                <div class="form-select">
                                    <label for="cate" class="blind">항목</label>
                                    <select
                                        id="cate"
                                        v-model="srchType"
                                    >
                                        <option value="search_like" selected="selected">제목+내용</option>
                                        <option value="subject_like">제목</option>
                                        <option value="contents_like">내용</option>
                                    </select>
                                </div>
                                <div class="form-box">
                                    <label for="search" class="blind">검색</label>
                                    <input type="text"
                                        id="search"
                                        v-model="srchKeyword"
                                        @keyup.enter="onSearch"
                                        placeholder="검색어를 입력해 주세요"
                                    >
                                </div>
                            </div>

                            <button type="button" title="검색" class="btn btn-primary" @click="onSearch">
                                검색 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_search.png" alt="검색"></i>
                            </button>
                        </div>

                        <div class="conts-box">
                            <table class="tbl notice">
                                <thead>
                                <tr>
                                    <th scope="col">번호</th>
                                    <th scope="col">제목</th>
                                    <th scope="col">작성자</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in boardList"
                                        :key = index
                                    >
                                        <td scope="row">{{ item.IDX }}</td>
                                        <td class="text-left">
                                            <router-link :to="{ name:'OnlineView', params: { id:item.IDX } }">
                                                <span class="subject ellipsis">{{ item.SUBJECT }}</span>
                                                <span class="date">{{ item.CREATIONDATE.split(' ')[0].replaceAll('-','.') }}</span>
                                            </router-link>
                                        </td>
                                        <td>{{ item.NAME }}</td>
                                    </tr>
                                    <tr>
                                        <td v-if="!boardList.length" colspan="3">게시글이 없습니다.</td>
                                    </tr>
                                </tbody>
                            </table>


                            <!-- paging start -->
                            <div class="paging" v-if="boardList.length !== 0">
                                <v-pagination
                                    v-model="currentPage"
                                    :pages=numberOfPages
                                    :rangeSize="1"
                                    activeColor="#436CFF"
                                    @update:modelValue="updateHandler(currentPage, categoryValue)"
                                />                        
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>

</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, computed, onUnmounted, getCurrentInstance, watch } from 'vue';
import { useRouter }from 'vue-router'
import { useApicall } from '@/composables/commonFn';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import VPagination from "@hennge/vue3-pagination";


export default {
    name: 'OnlineList',
    components:{
        Header,
        Footer,
        SubTitle,
        VPagination,
    }, 
    setup() {
        const store = useStore();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const { apiCall_g } = useApicall();
        const router = useRouter();
        const boardList = ref([]);
        const limit = ref(10);
        const currentPage = ref(1);
        const numberOfData = ref(0);
        const numberOfPages = computed(() => {
            return Math.ceil(numberOfData.value/limit.value)
        });
        const srchType = ref('search_like');
        const srchKeyword = ref('');
        
        const categoryValue = computed(() => store.state.currentOnlineCategory);
        const page_store = computed(() => store.state.currentPage);
        const currOnlinePage_store = computed(() => store.state.currentOnlinePage);
        const isLogin = computed(() => store.state.isLogin);


        store.commit('CHANGE_CURRENT_PAGE', 'Online');

        onMounted(() => {
            if(!isLogin.value) {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"로그인이 필요합니다." });
                router.push({
                    name:'OnlineEmail'
                });
            }

            // console.log(categoryValue.value);
            document.getElementById(categoryValue.value).classList.add('category-on');
            updateHandler(currOnlinePage_store.value, categoryValue.value);
        });

        // watch(() => isLogin.value, (newValue, oldValue) => {
        watch(() => isLogin.value, (newValue) => {
            if(newValue == false) {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"로그아웃 되었습니다." });
                router.push({
                    name:'OnlineEmail'
                });                  
            }
        });

        const updateHandler = (e,  _cVal) => {
            getDataList(e, _cVal);
        }

        const getDataList = async ( page = currentPage.value, categoryValue ) => {
            currentPage.value = page;
            store.commit('CHANGE_NOTICE_CURRENT_PAGE', page);

            var offset = (currentPage.value - 1) * limit.value;
            var getUrl = `/admin/counselinglist?limit=${limit.value}&offset=${offset}&category=${categoryValue}`;

            if( srchKeyword.value ) {
                var addurl = `&${srchType.value}=${srchKeyword.value}`;
                getUrl += addurl;
            }

            var boardDataList = await apiCall_g(getUrl);

            try {
                if(boardDataList.dataList == null) boardDataList.dataList = [];
                boardList.value = boardDataList.dataList;
                numberOfData.value = boardDataList.dataCount;
                
            } catch (error) {
                boardList.value = [];
                numberOfData.value  = 0;
            }
        }    

        const onSearch = () => {
            if( srchKeyword.value !== '' ) {
                updateHandler(1, categoryValue.value);
            } else {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"검색어를 입력하여 주세요." });
                return false;
            }
        }

        onUnmounted(()=> {     
            if(page_store.value !== 'Online') {
                store.commit('CHANGE_ONLINE_CURRENT_PAGE', 1);
                store.commit('CHANGE_ONLINE_CURRENT_CATEGORY', 'email');
            }
        })

        const onClickCategory = (e) => {
            var getEl = document.querySelector('.tab-category-area .category-on');
            // console.log(e, e.target)
            store.commit('CHANGE_ONLINE_CURRENT_CATEGORY', e.target.id);
            
            if(e.target !== getEl) {
                getEl.classList.remove('category-on');
                e.target.classList.add('category-on');
                srchType.value = 'search_like';
                srchKeyword.value = '';
                updateHandler(1, e.target.id);
            } else {
                return false;
            }
        }


        return {
            onSearch,
            boardList,
            currentPage,
            categoryValue,
            numberOfPages,
            updateHandler,
            srchType,
            srchKeyword,
            page_store,
            isLogin,
            onClickCategory,
        }
    }    
}
</script>
