<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

        <!-- section -->
        <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                    <div class="sec-title">
                        <h3>사업분야 <span>Business Area</span></h3>
                    </div>
                    <div class="sec-title-txt">
                        <p>소프트엠파이어만의 고객 만족 맞춤서비스</p>
                    </div>
                </div>

                <div class="conts-area">
                    <div class="conts-box business-area">

                        <div class="business-conts">
                            <span class="img"><img src="@/assets/images/contents/ico_business_01.png" alt="streaming"></span>
                            <div class="title-box">
                                <div class="title">
                                    <h3>스트리밍 서비스</h3>
                                    <span>Streaming Service</span>
                                </div>
                                <div class="txt">인터넷방송 / VOD / LIVE<br>음원 서비스 플랫폼 개발</div>
                            </div>
                            <div class="txt-box">
                                <ul>
                                    <li>콘텐츠 : 제작 및 편집 도구 개발</li>
                                    <li>백오피스 : 콘텐츠 관리시스템(CMS) 구축</li>
                                    <li>결제서비스 : 결제 처리 및 구독 관리 시스템</li>
                                    <li>보안서비스 : 사용자 인증 처리 및 DDOS, 해킹 방어 시스템 개발</li>
                                </ul>
                            </div>
                        </div>

                        <div class="business-conts">
                            <span class="img"><img src="@/assets/images/contents/ico_business_02.png" alt="cryptocurrency"></span>
                            <div class="title-box">
                                <div class="title">
                                    <h3>가상화폐 거래소 개발</h3>
                                    <span>cryptocurrency exchange<br>development</span>
                                </div>
                                <div class="txt">거래소 제작 및 컨설팅,<br>기술지원 및유지보수</div>
                            </div>
                            <div class="txt-box">
                                <ul>
                                    <li>거래 엔진 : 고성능 컴퓨팅 시스템과 알고리즘을 사용한 거래처리 시스템 제공</li>
                                    <li>보안시스템 : DDOS공격, 해킹,스팸 등으로부터 거래소 보호</li>
                                    <li>KYC/AML 시스템 : 사용자 신원확인 및 자금세탁방지를 위한 시스템 제공</li>
                                    <li>지갑관리시스템 : 안전하고 효율적인 지갑관리 시스템 제공</li>
                                    <li>커스터마이징 : 거래소만의 고유한 기능 추가</li>
                                </ul>
                            </div>
                        </div>

                        <div class="business-conts">
                            <span class="img"><img src="@/assets/images/contents/ico_business_03.png" alt="cryptocurrency"></span>
                            <div class="title-box">
                                <div class="title">
                                    <h3>솔루션 제공 웹 / 앱</h3>
                                    <span>Web / App solution<br>developement</span>
                                </div>
                                <div class="txt">홈페이지 제작 및 유지보수,<br>서버 호스팅 제공</div>
                            </div>                
                            <div class="txt-box">
                                <ul>
                                    <li>네이티브 앱 & 통합 플랫폼을 위한 플러터 앱 솔루션</li>
                                    <li>HTML5 기반의 최적화된 UI/UX 제공</li>
                                    <li>원본소스 제공, 기업메일 제공, 검색포털/검색엔진 등록</li>
                                    <li>온라인 신청,상담,챗봇,1:1채팅 프로그램 개발<br>(병의원,인테리어,학교/교육,차량,독서실,애견샾,건강/뷰티,여행/숙박 등)</li>
                                </ul>
                            </div>
                        </div>

                        <div class="business-conts">
                            <span class="img"><img src="@/assets/images/contents/ico_business_04.png" alt="markeing"></span>
                            <div class="title-box">
                                <div class="title">
                                    <h3>마케팅 프로그램 개발</h3>
                                    <span>markeing program<br>developement</span>
                                </div>
                                <div class="txt">바이럴 마케팅, 키워드 광고,<br>검색엔진 추출 관리 시스템 등</div>
                            </div>                
                            <div class="txt-box">
                                <ul>
                                    <li>바이럴마케팅, 키워드광고, 검색엔진추출 관리시스템</li>
                                    <li>웹사이트 검색결과노출 쿠팡,스마트스토어,블로그 등 순위관리 프로그램</li>
                                    <li>대량 이메일발송, 자동 문자발송</li>
                                    <li>인터넷,라디오,TV,SNS 등 광고대행 서비스</li>
                                </ul>
                            </div>
                        </div>

                        <div class="business-conts">
                            <span class="img"><img src="@/assets/images/contents/ico_business_05.png" alt="solution"></span>
                            <div class="title-box">
                                <div class="title">
                                    <h3>HTS 솔루션 개발</h3>
                                    <span>HTS solution development</span>
                                </div>
                                <div class="txt">전 증권사 API를 이용한<br>맞춤형 HTS 개발</div>
                            </div>                
                            <div class="txt-box">
                                <ul>
                                    <li>전 증권사 API 이용한 맞춤형 HTS 개발</li>
                                    <li>자동매매프로그램 개발 :<br>기업가치평가, 투자정보, 산업통계, 해외데이터 반영 분석툴제공</li>
                                </ul>
                            </div>
                        </div>

                        <div class="business-conts">
                            <span class="img"><img src="@/assets/images/contents/ico_business_06.png" alt="custom"></span>
                            <div class="title-box">
                                <div class="title">
                                    <h3>고객 주문형 솔루션 개발</h3>
                                    <span>Develop custom solutions</span>
                                </div>
                                <div class="txt">인트라넷 / 그룹웨어,<br>자재관리 시스템, 모바일 게임 외 기타</div>
                            </div>
                            <div class="txt-box">
                                <ul>
                                    <li>인트라넷/그룹웨어 : 전자우편,메신져,문서관리,웹하드,전자결제서비스 등</li>
                                    <li>자재관리 시스템 : 반입,반출,구매현황,재고현황 관리 시스템</li>
                                    <li>모바일 게임 외 기타</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- //section -->

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';

export default {
    name: 'BusinessView',
    components:{
        Header,
        Footer,
        SubTitle,
    },  
    setup() {
        const store = useStore();
        store.commit('CHANGE_CURRENT_PAGE', 'Business');
        // console.log('Business:', store.state.currentPage)

        return {
            
        }
    }  
}
</script>
