module.exports = {
    URL: {
        apiSoftEmpireUrl: 'https://api.dev.softempire.kro.kr',
        softEmpireUrl: 'https://dev.softempire.kro.kr',
        
        // corsUrl: {
        //     headers: {
        //         'Content-Type': 'application/json;charset=UTF-8',
        //         'Access-Control-Allow-Origin': '*'
        //     },    
        //     proxy: {
        //         '/api/': {
        //           target: 'http://localhost:4000',
        //           changeOrigin: true,
        //           // pathRewrite: {
        //           //   '^/api': ''
        //           // }
        //         },
        //     }
        // }
       
    }

}