import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/index.vue';
import Company from '../views/company.vue';
import Business from '../views/business.vue';
import OnlineList from '../views/online.vue';
import OnlineView from '../views/online_view.vue';
import OnlineEmail from '../views/online_email.vue';
import OnlineMobile from '../views/online_mobile.vue';
import NoticeList from '../views/notice.vue';
import NoticeView from '../views/notice_view.vue';
import NoticeWrite from '../views/notice_write.vue';
import QnaList from '../views/qna.vue';
import QnaView from '../views/qna_view.vue';
import QnaWrite from '../views/qna_write.vue';

import SupportPassword from '../views/support_password.vue';
import Recruiting from '../views/recruiting.vue';
import RecruitingWrite from '../views/recruiting_write.vue';
import AdminLogin from '../views/admin_login.vue';
// import Guide from '../views/guide.vue';

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(){
        return { top: 0 }
    },
    routes: [  
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/company',
        name: 'Company',
        component: Company
    },
    {
        path: '/business',
        name: 'Business',
        component: Business
    },
    {
        path: '/online',
        name: 'OnlineList',
        component: OnlineList
    },
    {
        path: '/onlineView/:id',
        name: 'OnlineView',
        component: OnlineView
    },
    {
        path: '/onlineEmail',
        name: 'OnlineEmail',
        component: OnlineEmail
    },
    {
        path: '/onlineMobile',
        name: 'OnlineMobile',
        component: OnlineMobile
    },
    {
        path: '/notice',
        name: 'NoticeList',
        component: NoticeList
    },
    {
        path: '/noticeView/:id',
        name: 'NoticeView',
        component: NoticeView
    },
    {
        path: '/noticeWrite/:id?',
        name: 'NoticeWrite',
        component: NoticeWrite
    },
    {
        path: '/qna',
        name: 'QnaList',
        component: QnaList
    },
    {
        path: '/qnaView/:idx',
        name: 'QnaView',
        component: QnaView
    },
    {
        path: '/qnaWrite/:idx?',
        name: 'QnaWrite',
        component: QnaWrite
    },
    {
        path: '/supportPassword',
        name: 'SupportPassword',
        component: SupportPassword
    },
    {
        path: '/recruiting',
        name: 'Recruiting',
        component: Recruiting
    },
    {
        path: '/recruitingWrite',
        name: 'RecruitingWrite',
        component: RecruitingWrite
    },
    {
        path: '/adminLogin',
        name: 'AdminLogin',
        component: AdminLogin
    },
    // {
    //   path: '/guide',
    //   name: 'Guide',
    //   component: Guide
    // },
    ]
})

// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     component: About,
//   }
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   }
// ]

export default router
