<template>
  <Header/>

  <!-- container start -->
  <div id="container">

    <SubTitle />

    <!-- contents start -->
    <div id="contents">

        <!-- section -->
        <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                    <div class="sec-title">
                        <h3>공지사항 <span>Notice</span></h3>
                    </div>
                    <div class="sec-title-txt">
                        <p>소프트엠파이어에서 알려드립니다</p>
                    </div>
                </div>

                <!-- tab area -->
                <div class="sec-tab-area">
                    <ul>
                        <li><a href="javascript:;" class="on">공지사항</a></li>
                        <li><router-link :to="{ name: 'QnaList' }" >Q&amp;A</router-link></li>
                    </ul>
                </div>
            </div>

            <div class="section support">

                <div class="conts-area">
                    <div class="search">
                        <div class="form-area">
                            <div class="form-select">
                                <label for="cate" class="blind">항목</label>
                                <select
                                    id="cate"
                                    v-model="srchType"
                                >
                                    <option value="search_like" selected="selected">제목+내용</option>
                                    <option value="subject_like">제목</option>
                                    <option value="contents_like">내용</option>
                                </select>
                            </div>
                            <div class="form-box">
                                <label for="search" class="blind">검색</label>
                                <input type="text"
                                    id="search"
                                    v-model="srchKeyword"
                                    @keyup.enter="onNoticeSearch"
                                    placeholder="검색어를 입력해 주세요"
                                >
                            </div>
                        </div>

                        <button type="button" title="검색" class="btn btn-primary" @click="onNoticeSearch">
                            검색 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_search.png" alt="검색"></i>
                        </button>
                    </div>

                    <div class="conts-box">
                        <table class="tbl notice">
                            <thead>
                            <tr>
                                <th scope="col">번호</th>
                                <th scope="col">제목</th>
                                <th scope="col">조회수</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item, index) in boardList"
                                :key = index
                            >
                                <td scope="row">{{ item.IDX }}</td>
                                <td class="text-left">
                                    <router-link :to="{ name:'NoticeView', params: { id:item.IDX } }">
                                        <span class="subject ellipsis">{{ item.SUBJECT }}</span>
                                        <span class="date">{{ item.CREATIONDATE.split(' ')[0].replaceAll('-','.') }}</span>
                                    </router-link>
                                </td>
                                <td>{{ item.COUNT }}</td>
                            </tr>
                            <tr>
                                <td v-if="!boardList.length" colspan="3">게시글이 없습니다.</td>
                            </tr>
                            <!-- <tr>
                                <td scope="row">2</td>
                                <td class="text-left">
                                <router-link :to="{ name:'SupportView', params:{ id:'2' } }">
                                    <span class="subject ellipsis">문의사항 / 이메일문의 관련 안내입니다.</span>
                                    <span class="date">2023.08.25</span>
                                </router-link>
                                </td>
                                <td>35</td>
                            </tr>
                            <tr>
                                <td scope="row">1</td>
                                <td class="text-left">
                                <router-link :to="{ name:'SupportView', params:{ id:'1' } }">
                                    <span class="subject ellipsis">SOFTEMPIRE 홈페이지가 새롭게 오픈하였습니다.</span>
                                    <span class="date">2023.07.30</span>
                                </router-link>
                                </td>
                                <td>147</td>
                            </tr> -->
                            </tbody>
                        </table>

                        <div class="btn-area" v-if="isLogin">                        
                            <button type="button" title="등록" class="btn btn-primary" @click="onWrite">
                                <i class="btn-ico-l"><img src="@/assets/images/contents/ico_write.png" alt="등록"></i>등록
                            </button>
                        </div>

                        <!-- paging start -->
                        <div class="paging" v-if="boardList.length !== 0">
                            <v-pagination
                                v-model="currentPage"
                                :pages=numberOfPages
                                :rangeSize="1"
                                activeColor="#436CFF"
                                @update:modelValue="updateHandler(currentPage, categoryValue)"
                            />                        
                        </div>

                    </div>
                </div>

            </div>

      </div>
      <!-- //section -->

    </div>
    <!-- //contents end -->
  </div>
  <!-- //container end -->

  <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, computed, onUnmounted, getCurrentInstance } from 'vue';
import { useApicall } from '@/composables/commonFn';
import { useRouter }from 'vue-router'
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import VPagination from "@hennge/vue3-pagination";

export default {
    name: 'NoticeList',
    components:{
        Header,
        Footer,
        SubTitle,
        VPagination,
    },    
    setup() {
        const store = useStore();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const { apiCall_g } = useApicall();
        const router = useRouter();
        const boardList = ref([]);
        const limit = ref(10);
        const currentPage = ref(1);
        const numberOfData = ref(0);
        const numberOfPages = computed(() => {
            return Math.ceil(numberOfData.value/limit.value)
        });
        const categoryValue = ref('G');
        const srchType = ref('search_like');
        const srchKeyword = ref('');

        const page_store = computed(() => store.state.currentPage);
        const currNoticePage_store = computed(() => store.state.currentNoticePage);
        const isLogin = computed(() => store.state.isLogin);
        
        store.commit('CHANGE_CURRENT_PAGE', 'Support');

        onMounted(()=> {
            updateHandler(currNoticePage_store.value, categoryValue.value);
        })

        const updateHandler = (e,  _cVal) => {
            getDataList(e, _cVal);
        }

        const getDataList = async ( page = currentPage.value, categoryValue ) => {
            currentPage.value = page;
            store.commit('CHANGE_NOTICE_CURRENT_PAGE', page);

            var offset = (currentPage.value - 1) * limit.value;
            var getUrl = `/board/boardlist?limit=${limit.value}&offset=${offset}&category=${categoryValue}`;

            if( srchKeyword.value ) {
                var addurl = `&${srchType.value}=${srchKeyword.value}`;
                getUrl += addurl;
            }

            var boardDataList = await apiCall_g(getUrl);

            try {
                if(boardDataList.dataList == null) boardDataList.dataList = [];
                boardList.value = boardDataList.dataList;
                numberOfData.value = boardDataList.dataCount;
                // console.log(boardList.value)
                
            } catch (error) {
                boardList.value = [];
                numberOfData.value  = 0;
            }
        }    

        const onNoticeSearch = () => {
            if( srchKeyword.value !== '' ) {
                updateHandler(1, categoryValue.value);
            } else {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"검색어를 입력하여 주세요." });
                return false;
            }
        }

        onUnmounted(()=> {     
            if(page_store.value !== 'Support') {
                store.commit('CHANGE_NOTICE_CURRENT_PAGE', 1);
            }
        })

        const onWrite = () => {
            // console.log('onWrite:',);
            router.push({
                name:'NoticeWrite'
            });            
        }

        return {
            onNoticeSearch,
            boardList,
            currentPage,
            categoryValue,
            numberOfPages,
            updateHandler,
            srchType,
            srchKeyword,
            page_store,
            onWrite,
            isLogin,
        }
    }     
}
</script>
