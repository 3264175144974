<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

            <!-- section -->
            <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                <div class="sec-title">
                    <h3>공지사항 <span>Notice</span></h3>
                </div>
                <div class="sec-title-txt">
                    <p>소프트엠파이어에서 알려드립니다</p>
                </div>
                </div>

            </div>

            <div class="section support">
                <div class="conts-area">
                <div class="conts-box">

                    <div class="support-view notice">
                        <div class="subject-box">
                            <div class="subject-inner">
                                <span class="subject">{{ boardItem.SUBJECT }}</span>
                                <span class="date">{{ boardItem.CREATIONDATE }} <span class="write">/ 관리자</span></span>
                            </div>
                        </div>
                        <div class="contents-box">
                            <div class="view-conts" v-html="boardItem.CONTENTS">
                            </div>
                        </div>
                    </div>

                    <div class="btn-area">
                        <button type="button" title="수정" class="btn btn-warning" v-if="isLogin" @click="onNoticeModify">
                            <i class="btn-ico-l"><img src="@/assets/images/contents/ico_option.png" alt="수정"></i>수정
                        </button>                           
                        <button type="button" title="삭제" class="btn btn-danger" v-if="isLogin"  @click="onNoticeDelete">
                            <i class="btn-ico-l"><img src="@/assets/images/contents/ico_trash.png" alt="삭제"></i>삭제
                        </button>                 
                        <router-link :to="{ name:'NoticeList' }" class="btn btn-outline-w">
                            <i class="btn-ico-l"><img src="@/assets/images/contents/ico_list.png" alt="목록"></i>목록
                        </router-link>                                  
                    </div>

                </div>
                </div>
            </div>

            </div>
            <!-- //section -->

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute, useRouter }from 'vue-router'
import { ref, onMounted, onUnmounted, computed, getCurrentInstance } from 'vue';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import { useApicall } from '@/composables/commonFn';

export default {
    name: 'NoticeView',
    components:{
        Header,
        Footer,
        SubTitle,    
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const boardItem = ref({});
        const { apiCall_g, apiCall_p } = useApicall();

        store.commit('CHANGE_CURRENT_PAGE', 'Support');
        const page_store = computed(() => store.state.currentPage);
        const isLogin = computed(() => store.state.isLogin);
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

        // console.log('Support:', store.state.currentPage)
        // const routeParamsId = ref('');

        const  getBoardItem = async (id) => {
            const res = await apiCall_g(`board/boardview?idx=${id}`);
            return res.data;
        }

        onMounted(async() => {          
            // emitter.emit('EVENT_CHECK_LOGIN');    
            boardItem.value = await getBoardItem(route.params.id);            
        })

        onUnmounted(()=> {
            if(page_store.value !== 'Support') {
            store.commit('CHANGE_NOTICE_CURRENT_PAGE', 1);
            }
        })


        const onWrite = () => {
            router.push({
                name:'NoticeWrite'
            });
        }

        const onNoticeModify = () => {
            // console.log('onModify:', route.params.id);
            emitter.emit('EVENT_CONFIRM',{ title:"알림", body:"공지사항을 수정하시겠습니까?", fn:onModify });        
        }
        const onModify = () => {
            router.push({
                name:'NoticeWrite',
                params: {
                    id: route.params.id,
                }
            });             
        }

        const onNoticeDelete = () => {            
            emitter.emit('EVENT_CONFIRM',{ title:"알림", body:"공지사항을 삭제하시겠습니까?", fn:onDelete });
        }

        const onDelete = async() => {
            var res = await apiCall_p(`/admin/boarddelete/${route.params.id}`, null, 'DEL');
            if(res.returnVal == '1') {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"공지사항이 삭제 되었습니다."});
                router.push({
                    name: 'NoticeList',
                })                
            } else {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"삭제 실패 하였습니다. 다시 확인하여 주십시오."});
            }
        }

        return {
            boardItem,
            store,
            isLogin,
            onWrite,
            onNoticeModify,
            onNoticeDelete,
            
        }
    }     
}
</script>
