<template>
  <Header/>

  <!-- container start -->
  <div id="container">

    <SubTitle />

    <!-- contents start -->
    <div id="contents">

      <!-- section -->
      <div class="sub-section bg-type-2">
        <div class="section">
          <div class="title-area">
            <div class="sec-title">
              <h3>관리자로그인 <span>Log in as administrator.</span></h3>
            </div>
            <div class="sec-title-txt">
              <p>Admin Login</p>
            </div>
          </div>
        </div>

        <div class="section password">
          <div class="conts-area">
            <div class="conts-box">
              <span class="img"><img src="@/assets/images/contents/ico_lock_warnning.png" alt="lock"></span>
              <p><strong class="fc-primary">비밀번호</strong>를 입력하세요.</p>
              <!-- <span class="txt">2 ~ 4 글자 숫자만 가능</span> -->

              <div class="form-box">
                <label for="uPw">비밀번호</label>
                <input type="password" id="uPw" placeholder="비밀번호를 입력해 주세요.">
              </div>

              <div class="btn-area d-flex-center mb-0">
                <button type="button" title="확인" class="btn btn-primary" @click="onPassword">
                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_lock_open.png" alt="확인"></i>확인
                </button>
                <router-link :to="{ name:'Support' }" class="btn btn-outline-w">
                  <i class="btn-ico-l"><img src="@/assets/images/contents/ico_cancel.png" alt="작성완료"></i>취소
                </router-link>
              </div>

            </div>
          </div>
        </div>


      </div>
      <!-- //section -->

    </div>
    <!-- //contents end -->
  </div>
  <!-- //container end -->

  <Footer/>
</template>

<script>
import { useStore } from 'vuex';
 import { getCurrentInstance } from 'vue';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';

export default {
  name: 'AdminLogin',
  components:{
    Header,
    Footer,
    SubTitle,    
  },    
  setup() {
    const store = useStore();
    store.commit('CHANGE_CURRENT_PAGE', 'AdminLogin');
    // console.log('Recruiting:', store.state.currentPage)

    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
    
    const onPassword = () => {
      emitter.emit('EVENT_ALERT',{title:"알림", body:"비밀번호 확인", fn:goLogin });
      // emitter.emit('EVENT_ALERT',{title:"알림", body:"로그인 후 사용가능합니다.", fn:goLoginPage});
    }

    const goLogin = () => {
      console.log('로그인완료');
    }

    return {
      onPassword
    }
  }    
}
</script>
