<template>
 <Header/>

<!-- container start -->
<div id="container">

    <SubTitle />

    <!-- contents start -->
    <div id="contents">

        <!-- section -->
        <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                    <div class="sec-title">
                        <h3>공지사항 <span>Notice</span></h3>
                    </div>
                    <div class="sec-title-txt">
                        <p>공지사항을 등록/수정합니다.</p>
                    </div>
                </div>
            </div>

            <div class="section online">
                <div class="conts-area">
                    <div class="conts-box">
                        <form>
                            <div class="form-box">
                                <label for="noticeSubject">제목<i class="im">&#42;</i></label>
                                <input type="text" id="noticeSubject" ref="noticeSubject" placeholder="제목을 입력해 주세요.">
                            </div>
                            <div class="form-box">
                                <label>내용<i class="im">&#42;</i></label>
                                <textarea id="noticeContent" ref="noticeContent" class="hidden" placeholder="내용을 입력해 주세요."></textarea>
                                <QuillEditor
                                    id="boardEditor"
                                    theme="snow"
                                    rows="10"
                                    ref="rContent"
                                    style="height:250px;"
                                    :options="editorOption"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="btn-area d-flex-center">
                <button type="button" title="작성완료" class="btn btn-primary" @click="onComplated">
                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_check_w.png" alt="작성완료"></i>작성완료
                </button>
                <router-link :to="{ name:'NoticeList' }" class="btn btn-outline-w">
                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_cancel.png" alt="취소"></i>취소
                </router-link>
            </div>

        </div>
        <!-- //section -->

    </div>
    <!-- //contents end -->
</div>
<!-- //container end -->

<Footer/>
</template>

<script>
import { useStore } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import { useApicall } from '@/composables/commonFn';
import { useRoute, useRouter }from 'vue-router'
import { ref, getCurrentInstance, onMounted, watch, computed } from 'vue';
import axios from '/axios';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import Config from '/public/config.js'

export default {
    name: 'NoticeWrite',
    components:{
        Header,
        Footer,
        SubTitle,
        QuillEditor,  
    },    
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();        
        const { apiCall_g } = useApicall();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const noticeSubject = ref(null);
        const noticeContent = ref(null);
        const rContent = ref(null);
        const boardCategory = ref('G');
        const boardItem = ref({});
        const isLogin = computed(() => store.state.isLogin);

        store.commit('CHANGE_CURRENT_PAGE', 'Support');
        // console.log('Recruiting:', store.state.currentPage)

        //mode = 1:쓰기 2:수정
        var mode = 1;
        
        onMounted( async() => {
            // console.log('mode', mode, route.params.id)
            if(route.params.id) {
                mode = 2;
                boardItem.value = await getModifyBoardItem(route.params.id);            
                noticeSubject.value.value = boardItem.value.SUBJECT;
                rContent.value.setHTML(boardItem.value.CONTENTS);
                // console.log('onMounted', boardItem.value, boardItem.value.NAME)
            } else {
                mode = 1;
            }
        });

        const onComplated = async() => {
            // console.log('onComplated', '작성완료:', uName.value.value, chk1.value, uPw.value.value, qnaSubject.value.value, qnaContent.value.value, qnaFileData.value, qnaIsScret);            

            if( noticeValidation() ) {
                var formData = new FormData();
                var requestUrl = '';
                var contentString = rContent.value.getHTML();
                // var contentString = rContent.value.getHTML().replace(/<[^>]*>?/g, '');
                noticeContent.value.value = contentString;

                if(mode == 1) {
                    formData.append('category', boardCategory.value);
                    requestUrl = '/admin/boardwrite';
                } else {
                    formData.append('idx', route.params.id);
                    requestUrl = '/admin/boardmodify';            
                }

                formData.append('subject', noticeSubject.value.value);
                formData.append('contents', noticeContent.value.value);

                // if(mode == 1 || mode == 2) {                    
                //     console.log('onComplated:', boardCategory.value, noticeSubject.value.value, noticeSubject.value.value, route.params.id);
                //     return false;
                // }

                axios.post(requestUrl, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then(function(response) {
                    let res = response.data;

                    if (res.returnCode == 1) {
                        if (res.resultMap.returnVal == 1) {
                            emitter.emit('EVENT_ALERT',{ title:"알림", body:"공지사항 등록이 완료되었습니다." });
                            router.push({
                                name: 'NoticeList'
                            })
                            resetFormFeild();
                        } else {
                            emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        }
                    } else {
                        emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        // console.log(res.message);
                    }
                })
                .catch(function(error) {
                    console.log(error)
                });

            }
        }

        const noticeValidation = () => {
            const warningText = document.querySelectorAll('.warning-txt');
            const warningClass = document.querySelectorAll('.warning');

            warningText.forEach(txt => txt.remove());
            warningClass.forEach(cl => cl.classList.remove('warning'));

            if (noticeSubject.value.value.trim() == '') {
                displayWarning(noticeSubject.value, '* 공지사항 제목을 입력해주세요.');
                return false;
            }

            if (rContent.value.getHTML().replace(/<[^>]*>?/g, '') == '') {
                displayWarning(document.querySelector('.ql-editor'), '* 공지사항 내용을 입력해주세요.');
                return false;
            }

            return true;
        };

        const displayWarning = (element, message) => {
            element.classList.add('warning');
            element.focus();
            
            const warningElement = document.createElement('span');
            warningElement.classList.add('warning-txt');
            warningElement.textContent = message;
            element.parentNode.appendChild(warningElement);
        };

        const resetFormFeild = () => {
            noticeSubject.value.value = '';
            noticeContent.value.value = '';
        }

        const getModifyBoardItem = async( id ) => {   
            const res = await apiCall_g(`/admin/boardview?idx=${id}`);
            return res.data;
        }


        watch(() => isLogin.value, (newValue) => {
            if(newValue == false) {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"로그아웃 되었습니다." });
                router.push({
                    name:'NoticeList'
                });                  
            }
        });

        return {
            onComplated,
            noticeSubject,
            noticeContent,
            boardCategory,
            boardItem,
            rContent,
            isLogin,
            editorOption : {
                modules: {
                    toolbar: [
                        [{ size: ["small", false, "large", "huge"] }], //class 제어 - html로 되도록 확인
                        ["bold", "underline"], // <strong>, <em>, <u>, <s>
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }], // class
                        ["link", "image"],
                    ],
                }
            }            
        }
    }    
}
</script>
