<template>
  <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

            <!-- section -->
            <div class="sub-section bg-type-2">
                <div class="section">
                    <div class="title-area">
                        <div class="sec-title">
                            <h3>묻고답하기 <span>Questions and answers</span></h3>
                        </div>
                        <div class="sec-title-txt">
                            <p>Q&A</p>
                        </div>
                    </div>
                </div>

                <div class="section support">
                    <div class="conts-area">
                        <div class="conts-box">

                            <div class="support-view">
                                <div class="subject-box">
                                    <div class="subject-inner">
                                        <span class="ico" v-if="boardItem.ISSCRET == 'Y'"><img src="@/assets/images/contents/ico_lock.png" alt="lock"></span>
                                        <span class="subject">{{ boardItem.SUBJECT }}</span>
                                        <span class="date">{{ boardItem.MODIFICATIONDATE }}<span class="write"> / {{ boardItem.NAME }}</span></span>
                                    </div>
                                    <div class="reply-inner">
                                        <span class="hold" v-if="boardItem.REPLY_OK == 'N'">답변대기</span>
                                        <span class="complate" v-else>답변완료</span>
                                    </div>
                                </div>
                                <div class="contents-box">
                                    <div class="q">Q</div>
                                    <div class="view-conts">
                                        {{ boardItem.CONTENTS }}
                                        <div class="qna-File" v-if="boardItem.ISFILE == 'Y'" >
                                            <div class="img"><img :src="fileImgUrl" alt="첨부파일"></div>
                                            <div class="name">첨부파일 : <a :href="fileImgUrl" target="_blank" download>{{ boardItem.FILE_NAME }}</a></div>
                                        </div>
                                    </div>
                                    <div class="date">{{ boardItem.MODIFICATIONDATE }}</div>
                                </div>
                                <div class="contents-box" v-if="boardItem.REPLY_OK == 'Y'">
                                    <div class="a">A</div>
                                    <div class="view-conts" v-html="boardItem.REPLY">
                                    </div>
                                    <!-- <div class="view-conts">
                                        {{ boardItem.REPLY }}
                                    </div>   -->
                                    <!-- <div class="date">2023.07.17<br>12 : 24 : 59</div> -->
                                </div>
                                <div class="contents-box" v-if="isReplyDropdown">
                                    <div class="a">A</div>
                                    <div class="view-conts">
                                        <form>
                                            <QuillEditor
                                                id="boardEditor"
                                                theme="snow"
                                                rows="10"
                                                ref="rContent"
                                                style="height:180px;"
                                                :options="editorOption"
                                            />
                                            <input
                                                type="hidden"
                                                id="hiddenInputReply"
                                                nama="hiddenInputReply"
                                                ref="inReply"
                                            >
                                        </form>

                                        <div class="reply-btn-area">
                                            <button type="button" title="답변" class="btn btn-primary" @click="onReplyComplated">
                                                <i class="btn-ico-l"><img src="@/assets/images/contents/ico_write.png" alt="완료"></i>답변완료
                                            </button>                                       
                                            <button type="button" title="삭제" class="btn btn-gray" @click="onReplyClose">
                                                <i class="btn-ico-l"><img src="@/assets/images/contents/ico_cancel.png" alt="취소"></i>취소
                                            </button>                                     
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="btn-area">
                                <button type="button" title="수정" class="btn btn-warning" @click="onQnaModify" v-if="boardItem.REPLY_OK == 'N' && !isLogin">
                                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_option.png" alt="수정"></i>수정
                                </button>
                                <button type="button" title="삭제" class="btn btn-danger" @click="onQnaDelete" v-if="boardItem.REPLY_OK == 'N' || isLogin">
                                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_trash.png" alt="삭제"></i>삭제
                                </button>
                                <button type="button" title="답변" class="btn btn-primary" @click="onReplyToggle" v-if="isLogin && !isReplyDropdown && boardItem.REPLY_OK == 'N'">
                                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_write.png" alt="답변"></i>답변
                                </button>
                                <router-link :to="{ name:'QnaList' }" class="btn btn-outline-w">
                                    <i class="btn-ico-l"><img src="@/assets/images/contents/ico_list.png" alt="목록"></i>목록
                                </router-link>                                  
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <!-- //section -->

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

  <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute, useRouter }from 'vue-router'
import { onMounted, ref, computed, getCurrentInstance, watch } from 'vue';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import { useApicall } from '@/composables/commonFn';
import Config from '/public/config.js'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from '/axios'

export default {
    name: 'QnaView',
    components:{
        Header,
        Footer,
        SubTitle,
        QuillEditor
    },

    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const { apiCall_g, apiCall_p } = useApicall();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        store.commit('CHANGE_CURRENT_PAGE', 'Support');

        // const currentTab = ref(1);
        const boardItem = ref({});
        const qnaPasswd = computed(() => store.state.qnaCurrentPasswd);
        const fileImgUrl = ref('');
        const rContent = ref(null);
        const isReplyDropdown = ref(false);
        const isLogin = computed(() => store.state.isLogin);
        const inReply = ref(null);

        onMounted( async()=> {
            // console.log('onMounted', route.params.idx, qnaPasswd.value);

            if(!isLogin.value) {
                if(!qnaPasswd.value) {
                    emitter.emit('EVENT_ALERT',{ title:"알림", body:"비밀번호를 확인하여 주십시오." });
                    router.push({
                        name: 'QnaList',
                    })
                    return false;
                }
            }

            boardItem.value = await getBoardItem(route.params.idx);
            fileImgUrl.value = `${Config.URL.softEmpireUrl}${boardItem.value.FILE_DIR}/${boardItem.value.FILE_REAL_NAME}`;

            // console.log('boardItem.value', boardItem.value, isLoginCoo.value);
        })

        const getBoardItem = async( idx ) => {
            var pw = qnaPasswd.value;
            var getUrl = '';

            if(!isLogin.value) {
                getUrl = `/board/counselingview?idx=${idx}&passwd=${pw}`
            } else {
                getUrl = `/admin/counselingview?idx=${idx}`
            }
            const res = await apiCall_g(getUrl);
            return res.data;
        }

        const onQnaModify = () => {
            emitter.emit('EVENT_CONFIRM',{ title:"알림", body:"문의하신 내용을 수정하시겠습니까?", fn:qnaModify });
        }

        const qnaModify = () => {
            var getConFirmOk = document.getElementById('confirmOk');
            if(getConFirmOk) {
                router.push({
                    name: 'QnaWrite',
                    params: {
                        idx: route.params.idx
                    }
                })
                // console.log('클릭')
            }
        }

        const onQnaDelete = () => {
            emitter.emit('EVENT_CONFIRM',{ title:"알림", body:"문의하신 내용을 삭제하시겠습니까?", fn:qnaDelete });
        }

        const qnaDelete = async() => {
            var getUrl = '';

            if(!isLogin.value) {
                getUrl = `/board/counselingdelete/${boardItem.value.IDX}/${qnaPasswd.value}`
            } else {
                getUrl = `/admin/counselingdelete/${boardItem.value.IDX}`
            }

            var res = await apiCall_p(getUrl, null, 'DEL');
            if(res.returnVal == '1') {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"문의하신 내용이 삭제 되었습니다."});
                router.push({
                    name: 'QnaList',
                })                
            } else {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"삭제 실패 하였습니다. 다시 확인하여 주십시오."});
            }
        }

        const onReplyToggle = () => {
            if(!isLogin.value) return false;
            isReplyDropdown.value = !isReplyDropdown.value;
        }

        const onReplyComplated = () => {
            inReply.value.value = rContent.value.getHTML();

            var formData = new FormData();
            formData.append('idx', route.params.idx);
            formData.append('reply', inReply.value.value);
            formData.append('replyOk', 'Y');

            // console.log('onReplyComplated:', rContent.value.getHTML(), inReply.value.value);
            onReplyClose();

            // const res = await apiCall_g(`/member/logincheck`);
            // console.log('onReplyComplated', res)

            axios.post(`/admin/counselingmodify`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            )
            .then(function(response) {
                let res = response.data;
                // console.log("json: ", res);

                if (res.returnCode == 1) {
                    if (res.resultMap.returnVal == 1) {
                        // console.log("sueccess:");
                        emitter.emit('EVENT_ALERT',{ title:"알림", body:"답변 등록 완료되었습니다." });
                        router.push({
                            name: 'QnaList'
                        })
                    } else {
                        // console.log(res.message);
                        emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                    }
                } else {
                    emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                    // console.log(res.message);
                }
            })
            .catch(function(error) {
                console.log(error)
            });

        }

        const onReplyClose = () => {
            var contentString = rContent.value.getHTML().replace(/<[^>]*>?/g, '');
            if( contentString !== '') {
                rContent.value.setText('');
                // console.log(rContent.value.getHTML())
            }

            isReplyDropdown.value = false;
        }

        watch(() => isLogin.value, (newValue) => {
            if(newValue == false) {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"로그아웃 되었습니다." });
                router.push({
                    name:'QnaList'
                });                  
            }
        });


        return {
            boardItem,
            qnaPasswd,
            fileImgUrl,
            onQnaModify,
            onQnaDelete,
            isReplyDropdown,
            onReplyToggle,
            onReplyComplated,
            rContent,
            onReplyClose,
            isLogin,
            inReply,
            editorOption : {
                modules: {
                    toolbar: [
                        [{ size: ["small", false, "large", "huge"] }], //class 제어 - html로 되도록 확인
                        ["bold", "underline"], // <strong>, <em>, <u>, <s>
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ align: [] }], // class
                        ["link", "image"],
                        
                        // [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
                        // [{ header: 1 }, { header: 2 }], // <h1>, <h2>
                        // ["bold", "italic", "underline", "strike"], // <strong>, <em>, <u>, <s>                            
                        // ["blockquote", "code-block"], // <blockquote>, <pre class="ql-syntax" spellcheck="false">
                        // [{ color: [] }, { background: [] }], //style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"                            
                        // [{ direction: "rtl" }], //class 제어
                        // [{ script: "sub" }, { script: "super" }], // <sub>, <sup>
                        // [{ font: [] }], // 글꼴 class로 제어
                        // [{ indent: "-1" }, { indent: "+1" }], // class제어                          
                        // ["link", "image", "video"],
                        // ["clean"],
                    ],
                }
            }
            
        }
    }     
}
</script>
