<template>   
    <Modal>
        <template #title>
            {{ props.titleTxt }}
        </template>

        <template #body>
            <div class="modal-body-conts">
                {{ props.bodyTxt }}
            </div>
        </template>

        <template #footer>
            <!-- <button
                type="button" 
                class="btn btn-gray" 
                @click="onCancle"
            >
            취소
            </button> -->
            <button 
                type="button" 
                id="btnClose"
                class="btn btn-primary"
                @click="onOk"
            >
            확인
            </button>
        </template>     
    </Modal>

</template>

<script>
import Modal from "@/components/popups/ModalComponent.vue"

export default {
    components: {
        Modal
    },
    props: {
        titleTxt:{
        type:String,
        require:false
    },
    bodyTxt: {
        type:String,
        require:true
        },
    },
    emits:['ok'],

    setup( props, { emit } ){
        //  const onCancle = () =>{

        //     emit('cancle')
        //  }
        const onOk = () =>{
            emit('ok')
        }

        return {
            // onCancle,
            onOk,
            props,

        }
    }

}
</script>

<style>

</style>