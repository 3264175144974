<template>

<!-- header start -->
<div id="header" ref="header" class="scr-top">
    <div class="header-area">
        <div class="logo-area">
            <h1 class="logo" @click="onNavClick"><router-link :to="{ name: 'Home' }"><img src="@/assets/images/common/logo.png" alt="SoftEmpire"></router-link></h1>
            <div class="btn-nav-area">
                <span class="btn-nav" v-if="isNavOpen == false" @click="onNavOpen"><button type="button"><img src="@/assets/images/contents/ico_list.png" alt="nav"></button></span>
                <span class="btn-cancel" v-if="isNavOpen" @click="onNavClose"><button type="button">&times;</button></span>
            </div>
        </div>
        <ul class="nav" @click="onNavClick">
            <li><router-link :to="{ name: 'Company' }" :class="currentPage == 'Company' ? 'on':'' ">COMPANY</router-link></li>
            <li><router-link :to="{ name: 'Business' }" :class="currentPage == 'Business' ? 'on':'' ">BUSINESS</router-link></li>
            <li><a href="javascript:;" :class="currentPage == 'Online' ? 'on':'' " @click="changeRouterName">ONLINE</a></li>
            <li><router-link :to="{ name: 'NoticeList' }" :class="currentPage == 'Support' ? 'on':'' ">SUPPORT</router-link></li>
            <li><router-link :to="{ name: 'Recruiting' }" :class="currentPage == 'Recruiting' ? 'on':'' ">RECRUITING</router-link></li>
            <!-- <li class="guide"><router-link :to="{ name: 'Guide' }" :class="currentPage =='Guide' ? 'on':'' ">Guide</router-link></li> -->
        </ul>
    </div>
    <div class="header-bg"></div>
</div>
<!--//header end -->

</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter }from 'vue-router'

export default {
  name:'HeaderComponent',
  setup() {
    const store = useStore();
    const header = ref(null);
    const isNavOpen = ref(false);
    const currentPage = computed(() => store.state.currentPage);
    const isLogin = computed(() => store.state.isLogin);
    const router = useRouter();

    const onNavOpen = () => {
        document.body.classList.add('scr-none');
        header.value.classList.add('active');
        isNavOpen.value = true;
    }

    const onNavClose = () => {
        document.body.classList.remove('scr-none');
        header.value.classList.remove('active');
        isNavOpen.value = false;
    }

    const onNavClick = () => {
        document.body.classList.remove('scr-none');
        isNavOpen.value = false;
    }

    onMounted(() => {
        window.addEventListener('resize', handleWindowResize);
    })

    var timer = null;

    const handleWindowResize = () => {
        // const w = window.innerWidth;
        // const h = window.innerHeight;
        const head = document.getElementById('header');

        clearTimeout(timer);
        timer = setTimeout(() => {
            if( head.classList.contains('active') ) {
                document.body.classList.remove('scr-none');
                head.classList.remove('active');
                isNavOpen.value = false;
            } else {
                isNavOpen.value = false;
                return false;
            }
        }, 300);
    }

    var prevScrollpos = window.pageYOffset;

    setTimeout(() => {
        window.onscroll = () => {
            var header = document.getElementById('header');

            var currentScrollPos = window.pageYOffset;
            if (prevScrollpos > currentScrollPos) {
                if( header.classList.contains('scr-on') ) {
                    header.classList.remove('scr-on');
                }
            } else {
                header.classList.add('scr-on');
                header.classList.remove('scr-top');
            }
            prevScrollpos = currentScrollPos;
            if(prevScrollpos < 60) {
                header.classList.remove('scr-on');
                header.classList.add('scr-top');
            }
        }
    }, 500);


    const changeRouterName = () =>{
        isLogin.value == true ? router.push({ name:'OnlineList' }) : router.push({ name:'OnlineEmail' });
    }

    return {
        header,
        onNavOpen,
        isNavOpen,
        onNavClose,
        currentPage,
        onNavClick,
        handleWindowResize,
        changeRouterName,
        isLogin,
    }
  }
}
</script>

