<template>
    <!-- sub title -->
    <div class="sub-title-area" ref="subTitleArea">
      <div class="sub-title">
          <h2>{{ subTitle }}</h2>
          <span>{{ titleTxt }}</span>
      </div>
    </div>
</template>


<script>
import { useStore } from 'vuex';
import { ref, computed, onMounted } from 'vue';

export default {
  name:'SubTitleComponent',
  setup() {
    const store = useStore();
    const currentPage = computed(() => store.state.currentPage);
    const subTitleArea = ref(null);
    const subTitle = ref('');
    const titleTxt = ref('');

    onMounted(()=> {
      getCurrentPage();
    })
    
    const getCurrentPage = () => {
      var cPage = currentPage.value;
      var stName = '';
      var stTxt = '';
      var className = '';

      switch(cPage) {
        case 'Company':
          stName = 'COMPANY',
          stTxt = 'About SOFT EMPIRE'
          className = 'st-company'
          break;
        case 'Business':
          stName = 'BUSINESS',
          stTxt = 'Business software'
          className = 'st-business'
          break;
        case 'Online':
          stName = 'ONLINE',
          stTxt = 'Online inquiry'
          className = 'st-online'
          break;
        case 'Support':
          stName = 'SUPPORT',
          stTxt = 'Customer support'
          className = 'st-support'
          break;
        case 'Recruiting':
          stName = 'RECRUITING',
          stTxt = 'Softempire recruiting'
          className = 'st-recruiting'
          break;
        // case 'AdminLogin':
        //   stName = 'ADMIN LOGIN',
        //   stTxt = 'Log in as administrator.'
        //   className = 'st-online'
        //   break;
      }

      subTitle.value = stName;
      titleTxt.value = stTxt;
      subTitleArea.value.classList.add(className);

      // console.log('SubTitleComponent:', currentPage.value, subTitle.value, titleTxt.value)
    }

    return {
      currentPage,
      subTitleArea,
      subTitle,
      titleTxt,
    }
  }

}
</script>
