<template>
  <Header/>

  <!-- container start -->
  <div id="container">

    <SubTitle />

    <!-- contents start -->
    <div id="contents">

      <!-- section -->
      <div class="sub-section bg-type-2">
        <div class="section">
          <div class="title-area">
            <div class="sec-title">
                <h3>입사지원 <span>Apply</span></h3>
            </div>
            <div class="sec-title-txt">
              <p>소프트엠파이어는 당신을 기다립니다</p>
            </div>
          </div>
        </div>

        <div class="section online">
          <div class="conts-area">
            <div class="conts-box">
              <div class="form-box">
                  <label for="uName">이름<i class="im">&#42;</i></label>
                  <input type="text" id="uName" placeholder="이름을 입력해 주세요.">
              </div>
              <div class="form-col">
                <p class="form-col-tit">연락처</p>
                <div class="col">
                  <div class="form-select">
                    <label for="tNum1" class="blind">연락처</label>
                    <select id="tNum1">
                        <option value="0" selected="selected">02</option>
                        <option value="1">031</option>
                    </select>
                  </div>  
                  <span class="unit">-</span>
                  <div class="form-box">
                      <label for="tNum2" class="blind">번호</label>
                      <input type="tel" id="Num2" maxlength="4">
                  </div>
                  <span class="unit">-</span>
                  <div class="form-box">
                      <label for="tNum3" class="blind">번호</label>
                      <input type="tel" id="tNum3" maxlength="4">
                  </div>
                </div>
              </div>
              <div class="form-col">
                <p class="form-col-tit">휴대폰</p>
                <div class="col">
                  <div class="form-select">
                    <label for="pNum1" class="blind">휴대폰</label>
                    <select id="pNum1">
                        <option value="0" selected="selected">010</option>
                        <option value="1">011</option>
                    </select>
                  </div>  
                  <span class="unit">-</span>
                  <div class="form-box">
                      <label for="pNum2" class="blind">번호</label>
                      <input type="tel" id="pNum2" maxlength="4">
                  </div>
                  <span class="unit">-</span>
                  <div class="form-box">
                      <label for="pNum3" class="blind">번호</label>
                      <input type="tel" id="pNum3" maxlength="4">
                  </div>
                </div>
              </div>
              <div class="form-col">
                <p class="form-col-tit">이메일</p>
                <div class="col-email">
                  <div class="col">
                    <div class="form-box mail">
                        <label for="email1" class="blind">번호</label>
                        <input type="email" id="email1">
                    </div>
                    <span class="unit">@</span>
                    <div class="form-box mail">
                        <label for="email2" class="blind">번호</label>
                        <input type="email" id="email2">
                    </div>
                  </div>
                  <div class="form-select mail-s">
                    <label for="email3" class="blind">이메일</label>
                    <select id="email3">
                        <option value="0" selected="selected">naver.com</option>
                        <option value="1">daum.net</option>
                    </select>
                  </div>
                </div>                   
              </div>
              <div class="form-box">
                  <label for="eSubject">제목<i class="im">&#42;</i></label>
                  <input type="text" id="eSubject" placeholder="제목을 입력해 주세요.">
              </div>
              <div class="form-box">
                  <label for="eContent">내용<i class="im">&#42;</i></label>
                  <textarea id="eContent" placeholder="내용을 입력해 주세요."></textarea>
              </div>
              <div class="file-box">
                  <label>파일업로드</label>
                  <input type="file" id="inFile" name="inFile" class="hidden" accept=".jpg,.jpeg,.png,.gif">
                  <div class="file-inner">
                      <span class="pl">파일첨부</span>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section privacy">
          <div class="conts-box">
            <p class="title">개인정보 수집 및 이용에 대한 안내</p>
            <p class="txt">(주)소프트엠파이어에서는 기업/단체 및 개인의 정보 수집 및 이용 등 처리에 있어 아래의 사항을 관계법령에 따라 고지하고 안내해 드립니다.</p>
            <ol class="list">
              <li>정보수집의 이용 목적 : 상담 및 진행</li>
              <li>수집/이용 항목 : 이름, 일반전화, 휴대전화, 이메일, 상담내용</li>
              <li>보유 및 이용기간 : 상담 종료후 6개월, 정보제공자의 삭제 요청시 즉시</li>
              <li>개인정보처리담당 : 전화 031-715-2100 / 이메일 help@softempire.co.kr</li>
            </ol>
            <div class="checkbox-area">
              <div>
                <input type="checkbox" id="chk1">
                <label for="chk1">개인정보 수집 및 이용에 동의합니다.</label>
              </div>
              <span class="txt-pop" @click="onModalOpen">내용보기</span>
            </div>
          </div>
          
          <div class="btn-area d-flex-center">
            <button type="button" title="입사지원" class="btn btn-primary">
                입사지원 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_right_bg.png" alt="arrow"></i>
            </button>
          </div>
        </div>   


      </div>
      <!-- //section -->

    </div>
    <!-- //contents end -->
  </div>
  <!-- //container end -->

  <Footer/>

  <PrivacyModal
    v-if="showModal"
    @close="closeModal" 
    @ok="okModal" 
    :titleTxt="txtTitle_alert"
  />

</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import PrivacyModal from '@/components/popups/PrivacyModal.vue';

export default {
  name: 'RecruitingView',
  components:{
    Header,
    Footer,
    SubTitle,
    PrivacyModal,
  },    
  setup() {
    const store = useStore();
    store.commit('CHANGE_CURRENT_PAGE', 'Recruiting');

    const showModal = ref(false);
    const txtTitle_alert = ref('개인정보취급방침');

    const onModalOpen = () => {
      showModal.value = true;
      document.body.classList.add('scr-none');
    }   

    const okModal = () => {
      showModal.value = false;
      document.body.classList.remove('scr-none');
    }   

    const closeModal = () => {
      showModal.value = false;
      document.body.classList.remove('scr-none');
    }
    // console.log('Recruiting:', store.state.currentPage)
    
    return {
      showModal,
      okModal,
      closeModal,
      txtTitle_alert,
      onModalOpen,      
    }
  }    
}
</script>
