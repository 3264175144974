<template>
  <div id="wrap">
    <router-view></router-view>
  </div>

    <BasicModal1b
      v-if="showModal"
      @close="closeModal" 
      @ok="okModal" 
      :titleTxt="txtTitle_alert"
      :bodyTxt="txtBody_alert"
    />

    <BasicModal2b
      v-if="showModal2"
      @close="closeModal2"
      @cancle="closeModal2"
      @ok="okModal2" 
      :titleTxt="txtTitle_alert2"
      :bodyTxt="txtBody_alert2"
    />

</template>


<script>
import {onMounted, getCurrentInstance, ref} from 'vue';
import BasicModal1b from '@/components/popups/BasicModal1b.vue';
import BasicModal2b from '@/components/popups/BasicModal2b.vue';

export default {
  name: 'App',
  components: {
    BasicModal1b,
    BasicModal2b
  },
  setup() {

    const showModal = ref(false);        
    const txtTitle_alert = ref('')
    const txtBody_alert = ref('')
    const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

    const showModal2 = ref(false);        
    const txtTitle_alert2 = ref('')
    const txtBody_alert2 = ref('')

    var modalOKCallbackFn = null;
    var body = document.querySelector('body');

    emitter.on('EVENT_ALERT', (data) => {  
      body.children.modal.classList.add('alert');
      txtTitle_alert.value = data.title;
      txtBody_alert.value = data.body;
      showModal.value = true;
      if(data.fn) {
        modalOKCallbackFn =  data.fn;
      }

      // console.log('EVENT_ALERT', '알럿 테스트');
    })

    emitter.on('EVENT_CONFIRM', (data) => {  
      body.children.modal.classList.add('confirm');
      txtTitle_alert2.value = data.title;
      txtBody_alert2.value = data.body;
      showModal2.value = true;
      if(data.fn) {
        modalOKCallbackFn =  data.fn;
      }

      // console.log('EVENT_ALERT', '알럿 테스트');
    })

    onMounted(()=>{
    })

    const closeModal = () => {
      showModal.value = false;
      var alertClass = document.querySelector('#modal.alert');
      if(alertClass) alertClass.classList.remove('alert');      
    }

    const okModal = () => {
      showModal.value = false; 
      if( modalOKCallbackFn ) {
        modalOKCallbackFn();
        modalOKCallbackFn = null;
        // console.log("modalOKCallbackFn:", modalOKCallbackFn)
      }

      var alertClass = document.querySelector('#modal.alert');
      if(alertClass) alertClass.classList.remove('alert');
    }

    const closeModal2 = () => {
      showModal2.value = false;
      var confirmClass = document.querySelector('#modal.confirm');
      if(confirmClass) confirmClass.classList.remove('confirm');      
    }

    const okModal2 = () => {
      showModal2.value = false; 
      if( modalOKCallbackFn ) {
        modalOKCallbackFn();
        modalOKCallbackFn = null;
        // console.log("modalOKCallbackFn:", modalOKCallbackFn)
      }

      var confirmClass = document.querySelector('#modal.confirm');
      if(confirmClass) confirmClass.classList.remove('confirm');
    }
    
    return {
      showModal,
      closeModal,
      txtBody_alert,
      txtTitle_alert,
      showModal2,
      closeModal2,
      txtBody_alert2,
      txtTitle_alert2,
      okModal,
      okModal2
    }
  }
}

</script>

<style>
@import  './assets/css/common.css';
@import  './assets/css/style.css';

</style>
