<template>   
    <Modal> 
        <template #title>
            {{ props.titleTxt}}
        </template>

        <template #body>
            <div class="modal-body-conts">
                <div class="img">
                    <span><img src="@/assets/images/contents/ico_lock_warnning.png" alt="lock"></span>
                    <p>
                        <strong class="fc-primary">비밀번호</strong>를 입력하세요.<br>                 
                        <span class="fc-gray">(비밀번호는 숫자입력 최대 6자까지 가능합니다.)</span>
                    </p>
                </div>
                <div class="form-box mt-20">
                    <form>
                        <label for="inputPw" class="hidden">비밀번호</label>
                        <input
                            type="password"
                            id="inputPw"
                            maxlength="6"
                            ref="inputQnaPw"
                            v-model="inputPasswdNum"
                            autocomplete=""
                            @input="inputPasswdNum = $event.target.value.replace(/[^0-9]/g, '')" 
                        >
                    </form>
                </div>
                <span class="qna-pw-warning mt-10" ref="inputPasswdWarning"></span>
            </div>
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-gray" 
                @click="onCancle"
            >
            취소
            </button>
            <button 
                type="button" 
                id="btnClose"
                class="btn btn-primary"
                @click="onOk"
            >
            확인
            </button>
        </template>     
    </Modal>

</template>

<script>
import Modal from "@/components/popups/ModalComponent.vue"
import { useStore } from 'vuex';
import { useApicall } from '@/composables/commonFn';
import { ref, onMounted } from 'vue';
import { useRouter }from 'vue-router';

export default {
    components: {
        Modal
    },
    props: {
        titleTxt: {
            type:String,
            require:false
        },
        idx: {
            type:String,
            require:false
        },
    },
    emits:['cancle', 'ok'],

    setup(props, {emit}) {
        const store = useStore();
        const { apiCall_g } = useApicall();
        const router = useRouter();
        const inputQnaPw = ref(null);
        const inputPasswdWarning = ref('');
        const inputPasswdNum = ref(null);

        // console.log(props.idx)

        onMounted(()=> {
            inputQnaPw.value.focus();
            onModalKeyupEvent();
        })

        const onCancle = () => {
            emit('cancle')
        }

        const onOk = () => {
            // console.log('inputPasswdNum.value:', inputPasswdNum.value,)
            if( inputPasswdNum.value == '' || inputPasswdNum.value == null ) {
                inputPasswdWarning.value.textContent = '* 비밀번호를 입력하여 주십시오.'
                inputQnaPw.value.classList.add('warning');
                inputPasswdNum.value = '';
                return false;
                // console.log(inputQnaPw.value)
            }

            itemConfirmData(props.idx, inputPasswdNum.value);
        }

        const itemConfirmData = async (idx, passwd)=> {
            var msg = '';
            const res = await apiCall_g(`/board/counselingview?idx=${idx}&passwd=${passwd}`, (message) => {
                msg = message;
            });

            if(!res) {
                if(msg === '패스워드가 틀립니다.') {
                    inputPasswdWarning.value.textContent = '* 비밀번호가 틀립니다. 확인 후 다시 입력하여 주십시오.'
                    inputQnaPw.value.classList.add('warning');
                    inputPasswdNum.value = '';
                    return false;
                }
            }

            store.commit('CHANGE_QNA_PASSWORD', passwd);
            document.body.classList.remove('scr-none');
            inputPasswdNum.value = '';
            emit('ok');

            router.push({
              name: 'QnaView',
              params: {           
               idx: props.idx,
              }
            })

            // console.log('itemConfirmData:', res, msg)
        }

        const onModalKeyupEvent = () => {
            // inputQnaPw.value
            inputQnaPw.value.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    onOk();
                }
            });
        }


        return {
            onOk,
            onCancle,
            props,
            inputQnaPw,
            inputPasswdWarning,
            inputPasswdNum,
            onModalKeyupEvent,
        }
    }

}
</script>

<style>

</style>