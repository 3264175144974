// import { useStore } from 'vuex';
import axios from '/axios';



export const useApicall = () => {

    const apiCall_g = async(_url, messageFn = null) => {
        // var store = useStore();  
        try {
            const res = await axios.get(_url);
            let json =  res.data;
            
            if(json.returnCode == 1 )
            {
                if ( json.resultMap.returnVal == 1 ) 
                {
                   // console.log('apiCall_g :',_url,':', json.resultMap )
                    return json.resultMap;
                } 
                else 
                {                    
                   // console.log(_url + " :" +json.message);
                    if(messageFn)
                    {
                        messageFn(json.message)
                    }
                    return false;  
                }
            } 
            // else if(json.returnCode == -102 )
            // { 
            //     //alert("로그인이 필요합니다.");
            //     // store.commit('user/CHANGE_IS_LOGIN', false);                  
                
            //     if(messageFn)
            //     {
            //         messageFn("로그인 후 사용가능합니다.", json.returnCode )     
            //     }
            //    // return 'unLogin';
            //     return false;              
            // }          
            else
            {
               // console.log(_url + " :" +json.message);
                if(messageFn)
                {
                    messageFn(json.message)     
                }
                return false;  
            }
        } catch(error) {
            console.log(" apiCall_g  error", error);             
            return false;
        }
    }

    const apiCall_p = async(_url, _params, _type = null, messageFn = null)=>{ 
        // console.log("_url", _url, "_params", _params )
        try{ 

            var res = null;
            if(_type)
            {   
                if(_type == "PUT") {
                    res = await axios.put(_url, _params);
                }
                else if (_type == "DEL") {
                    if( _params !== null ) {
                        res = await axios.delete(_url, _params);
                    } else {
                        res = await axios.delete(_url);
                    }
                }

            }
            else
            {
                res = await axios.post(_url, _params);
            } 


            let json = res.data;

            if(json.returnCode == 1 )
            {
                if(json.resultMap.returnVal == 1)
                {              
                   return json.resultMap;
                }
                else
                {
                    // console.log(_url + " :" +json.message);
                    if(messageFn)
                    {
                       messageFn(json.message)     
                    }
                    return false;  
                }
            }
            // else if(json.returnCode == -102 )
            // {                    //alert("로그인이 필요합니다.");
            //     store.commit('user/CHANGE_IS_LOGIN', false);            
            //     if(messageFn)
            //     {
            //         messageFn("로그인 후 사용가능합니다.", json.returnCode )     
            //     }
            //    // return 'unLogin';
            //     return false;              
            // }
            else
            {
               console.log(_url + " :" +json.message);
                return false;  
            }
            
        }catch(error){     
                console.log("apiCall_p error", error);             
                return false;           
        }
    }



     return {
         apiCall_g,
         apiCall_p,
     }
 
 
 }