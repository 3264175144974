<template>   
    <Modal> 
        <template #title>
            {{ props.titleTxt }}
        </template>

        <template #body>
            <div class="modal-body-conts">
                <div class="img">
                    <span><img src="@/assets/images/contents/ico_lock_warnning.png" alt="lock"></span>
                    <p><strong class="fc-primary">아이디/비밀번호</strong>를 입력하세요.</p>
                </div>
                <div class="form-box mt-20">
                    <form>
                        <label for="adId" class="hidden">아이디</label>
                        <input
                            type="text"
                            id="adId"
                            v-model="inputAdminId"
                            autocomplete="값"
                            placeholder="아이디"
                        >
                        <span class="admin-warning" ref="adminIdWarning"></span>
                        <label for="adpw" class="hidden">비밀번호</label>
                        <input
                            type="password"
                            id="adpw"
                            class="mt-10"
                            v-model="inputAdminPw"
                            @keyup.enter="onOk"
                            autocomplete="값"
                            placeholder="비밀번호"
                        >
                        <span class="admin-warning" ref="adminPwWarning"></span>
                    </form>
                </div>
            </div>
        </template>

        <template #footer>
            <button
                type="button" 
                class="btn btn-gray" 
                @click="onCancle"
            >
            취소
            </button>
            <button 
                type="button" 
                id="btnClose"
                class="btn btn-primary"
                @click="onOk"
            >
            확인
            </button>
        </template>     
    </Modal>

</template>

<script>
import Modal from "@/components/popups/ModalComponent.vue"
import { useStore } from 'vuex';
import { useApicall } from '@/composables/commonFn';
import { ref, computed, getCurrentInstance  } from 'vue';
// import { useRouter }from 'vue-router';
import VueCookies from "vue-cookies";
// import axios from '/axios';

export default {
    components: {
        Modal
    },
    props: {
        titleTxt: {
            type:String,
            require:false
        },
    },    
    emits:['cancle', 'ok'],

    setup(props, {emit}) {
        const store = useStore();
        const { apiCall_p } = useApicall();
        const inputAdminId = ref('');
        const inputAdminPw = ref('');
        const adminIdWarning = ref(null);
        const adminPwWarning = ref(null);
        const isLogin = computed(() => store.state.isLogin);
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;

        const onCancle = () => {
            emit('cancle');
            removeClassPopup();
        }

        const onOk = () => {
            // emit('ok');
            // console.log('inputAdminId.value:', inputAdminId.value, inputAdminPw.value)

            if( inputAdminId.value == '' || inputAdminId.value == null ) {
                adminIdWarning.value.textContent = '* 아이디를 입력하여 주십시오.'
                document.getElementById('adId').classList.add('warning');
                inputAdminId.value = '';
                return false;
            }

            if( inputAdminPw.value == '' || inputAdminPw.value == null ) {
                adminIdWarning.value.textContent = '';
                adminPwWarning.value.textContent = '* 비밀번호를 입력하여 주십시오.'
                document.getElementById('adId').classList.remove('warning');
                document.getElementById('adpw').classList.add('warning');
                inputAdminPw.value = '';
                return false;
            }

            adminLoginConfirm(inputAdminId.value, inputAdminPw.value);
        }
        
        const adminLoginConfirm = async(id, passwd)=> {
            var params = {
                mid: id,
                passwd: passwd,
            };                

            var res = await apiCall_p(`/member/login`, params);

            if(res.returnVal == '1') {
                VueCookies.set('isLoginCookie', 'LoginCookie', '10h');
                store.commit('CHANGE_IS_LOGIN', true);                
                onCancle();
            } else {
                removeClassPopup();
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"아이디/비밀번호를 확인하여 주십시오.", fn:removeClassPopup});
                // alert('로그인 실패하였습니다. 아이디/비밀번호를 확인하여 주십시오.');
            }
        }

        const removeClassPopup = () => {
            var getModal = document.getElementById('modal');
            var getModalWrap = document.querySelectorAll('.modal-wrapper');
                
            // console.log(getModalWrap.length)
            if( getModalWrap.length == 1 ) {
                getModalWrap[0].style.display = 'none';
            } else {
                getModalWrap[0].style.display = 'block';
            }

            if( getModal.classList.contains('admin-login') ) {
                getModal.classList.remove('admin-login');
            } else {
                getModal.classList.add('admin-login');
            }
        }


        return {
            onOk,
            onCancle,
            props,
            inputAdminId,
            inputAdminPw,
            adminIdWarning,
            adminPwWarning,
            isLogin,
            // adminLoginTest,
            // inputQnaPw,
            // inputPasswdWarning,
            // inputPasswdNum,
            // onModalKeyupEvent,
        }
    }

}
</script>

<style>

</style>