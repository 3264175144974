<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

            <!-- section -->
            <div class="sub-section bg-type-2">
            <div class="section">
                <div class="title-area">
                    <div class="sec-title">
                        <h3>온라인 문의 <span>Contact us by online</span></h3>
                    </div>
                    <div class="sec-title-txt">
                        <p>고객 정보와 문의내용을 입력해주세요</p>
                    </div>
                </div>

            </div>

            <div class="section support">
                <div class="conts-area">
                <div class="conts-box">

                    <div class="support-view notice">
                        <div class="subject-box">
                            <div class="subject-inner">
                                <span class="subject">{{ boardItem.SUBJECT }}</span>
                                <span class="date">{{ boardItem.CREATIONDATE }}<span class="write">/ {{ boardItem.CATEGORY }}</span></span>
                            </div>
                            <div class="reply-inner">
                                <span class="fc-gray" style="border:0;">{{ boardItem.NAME }}</span>
                            </div>                            
                        </div>
                        <div class="contents-box">
                            <div class="view-conts">
                                {{ boardItem.CONTENTS }}
                                
                                <span class="divi-border"></span>
                                <p class="online-view-p">문의유형 : {{ boardItem.CATEGORY }}</p>
                                <p class="online-view-p" v-if="boardItem.TEL !== null">TEL : {{ boardItem.TEL }}</p>
                                <p class="online-view-p" v-if="boardItem.PHONE !== null">PHONE : {{ boardItem.PHONE }}</p>
                                <p class="online-view-p" v-if="boardItem.EMAIL !== null">E-Mail : {{ boardItem.EMAIL }}</p>

                                <div class="qna-File" v-if="boardItem.ISFILE == 'Y'" >
                                    <div class="img"><img :src="fileImgUrl" alt="첨부파일"></div>
                                    <div class="name">첨부파일 : <a :href="fileImgUrl" target="_blank" download>{{ boardItem.FILE_NAME }}</a></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="btn-area">
                        <button type="button" title="삭제" class="btn btn-danger" @click="onDelete">
                            <i class="btn-ico-l"><img src="@/assets/images/contents/ico_trash.png" alt="삭제"></i>삭제
                        </button>
                        <router-link :to="{ name:'OnlineList' }" class="btn btn-outline-w">
                            <i class="btn-ico-l"><img src="@/assets/images/contents/ico_list.png" alt="목록"></i>목록
                        </router-link>                           
                    </div>

                </div>
                </div>
            </div>

            </div>
            <!-- //section -->

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>
</template>

<script>
import { useStore } from 'vuex';
import { useRoute, useRouter }from 'vue-router'
import { ref, onMounted, onUnmounted, computed, getCurrentInstance } from 'vue';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import { useApicall } from '@/composables/commonFn';
import Config from '/public/config.js'

export default {
    name: 'OnlineView',
    components:{
        Header,
        Footer,
        SubTitle,    
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const boardItem = ref({});
        const { apiCall_g, apiCall_p } = useApicall();
        const fileImgUrl = ref('');

        store.commit('CHANGE_CURRENT_PAGE', 'Online');
        const page_store = computed(() => store.state.currentPage);
        const isLogin = computed(() => store.state.isLogin);

        // console.log('Support:', store.state.currentPage)
        // const routeParamsId = ref('');

        const  getBoardItem = async (id)=> {
            const res = await apiCall_g(`admin/counselingview?idx=${id}`);
            return res.data;
        }

        onMounted(async()=>{          
            boardItem.value = await getBoardItem(route.params.id);     
            fileImgUrl.value = `${Config.URL.softEmpireUrl}${boardItem.value.FILE_DIR}/${boardItem.value.FILE_REAL_NAME}`;
            // console.log(isLogin.value);
        })

        onUnmounted(()=> {
            if(page_store.value !== 'Online') {
            store.commit('CHANGE_NOTICE_CURRENT_PAGE', 1);
            }
        })


        const onDelete = () => {
            emitter.emit('EVENT_CONFIRM',{ title:"알림", body:"문의하신 내용을 삭제하시겠습니까?", fn:onlineDelete });
        }

        const onlineDelete = async() => {
            var res = await apiCall_p(`/admin/counselingdelete/${route.params.id}`, null, 'DEL');
            if(res.returnVal == '1') {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"문의가 삭제 되었습니다."});
                router.push({
                    name: 'OnlineList',
                })                
            } else {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"삭제 실패 하였습니다. 다시 확인하여 주십시오."});
            }
        }        

        return {
            boardItem,
            store,
            isLogin,
            fileImgUrl,
            onDelete,
        }
    }     
}
</script>
