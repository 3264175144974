<template>
  <Header/>

  <!-- container start -->
  <div id="container">
    <SubTitle />

    <!-- contents start -->
    <div id="contents">

      <!-- section -->
      <div class="sub-section bg-type-2">
        <div class="section col-2">
          <div class="title-area">
            <div class="sec-title">
                <h3>CEO 인사말 <span>CEO greetings</span></h3>
            </div>
            <div class="sec-title-txt">
              <p class="mb-0">새로운 흐름을 주도하는 기업</p>
              <p class="mb-0">도전을 두려워하지 않는 기업</p>
              <p class="mb-0">고객이 만족하는 기업</p>
              <p class="bottom-name">대표이사 장원석</p>
            </div>
          </div>
          <div class="conts-area">
            <div class="conts-box greeting">
              (주)소프트엠파이어 대표이사 장원석입니다.<br>
              당사의 홈페이지를 방문해 주셔서 진심으로 감사드립니다.<br><br>
              소프트엠파이어는 맞춤형 소프트웨어 개발 및 네트워크 구축 서비스를
              제공하는 기업입니다.<br>
              어떠한 상황에서도 고객이 만족하는 솔루션과 시스템 제공을 약속합니다.<br><br>
              새로운 흐름을 주도하는 기업<br>
              변화와 도전을 두려워하지 않는 기업<br>
              고객이 만족하는 기업<br><br>
              최상의 서비스와 앞선 기술로 고객과 함께하는<br>
              글로벌 기업으로 도약하겠습니다.<br>
              감사합니다.<br>
            </div>
          </div>
        </div>
      </div>
      <!-- //section -->

      <!-- section -->
      <div class="sub-section">
        <div class="section col-2">
          <div class="title-area organ">
            <div class="sec-title">
                <h3>조직도 <span>Organizational</span></h3>
            </div>
            <div class="sec-title-txt">
              <span>사람 중심의 기업문화를</span> <span>선도합니다</span>
            </div>
          </div>
          <div class="conts-area">
            <div class="conts-box organ-img">
              <div v-if="isMoblieImg == true"><img src="@/assets/images/contents/organizational_m.png" alt="Organizational"></div>
              <div v-if="isMoblieImg == false"><img src="@/assets/images/contents/organizational.png" alt="Organizational"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- //section -->

      <!-- section -->
      <div class="sub-section bg-type-2">
        <div class="section col-2">
          <div class="title-area location">
            <div class="sec-title">
                <h3>찾아오시는 길 <span>Location</span></h3>
            </div>
            <div class="location-txt">
              <p>(주)소프트엠파이어</p>
              <p>(우) 13555</p>
              <p>경기도 성남시 분당구 성남대로 393 A715호 (구. 정자동 7)</p>
              <p>전화문의 : <a href="tel:0317152100">031-715-2100</a></p>
            </div>
            <div class="btn-area">
              <a href="http://kko.to/L2gib3R7Ei" class="btn" title="자세히보기" target="_blank">
                자세히보기 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_pin.png" alt="지도"></i>
              </a>
            </div>
          </div>
          <div class="conts-area">
            <div class="conts-box location-img">
              <img src="@/assets/images/contents/location.png" alt="location">
            </div>
          </div>
        </div>
      </div>
      <!-- //section -->


    </div>
    <!-- //contents end -->

  </div>
  <!-- //container end -->

  <Footer/>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';

export default {
  name: 'CompanyView',
  components:{
    Header,
    Footer,
    SubTitle,
  },
  setup() {
    const store = useStore();
    store.commit('CHANGE_CURRENT_PAGE', 'Company');
    const isMoblieImg = ref(true);

    onMounted(()=> {
      getWindowsSize();
    })

    const getWindowsSize = () => {
      window.innerWidth >= 768 ? isMoblieImg.value = false : isMoblieImg.value = true;
    }

    return {
      isMoblieImg,
      getWindowsSize,
    }
  }
}
</script>
