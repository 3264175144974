<template>
    <Header/>

    <!-- container start -->
    <div id="container">

        <SubTitle />

        <!-- contents start -->
        <div id="contents">

            <!-- section -->
            <div class="sub-section bg-type-2">
                <div class="section">
                    <div class="title-area">
                        <div class="sec-title">
                            <h3>온라인 문의 <span>Contact us by online</span></h3>
                        </div>
                        <div class="sec-title-txt">
                            <p>고객 정보와 문의내용을 입력해주세요</p>
                        </div>
                    </div>

                    <!-- tab area -->
                    <div class="sec-tab-area">
                        <ul>
                            <li v-if="isLogin"><a href="javascript:;" @click="onlineListGo">문의리스트</a></li>
                            <li><router-link :to="{ name: 'OnlineEmail' }" >이메일문의</router-link></li>
                            <li><a href="javascript:;" class="on">모바일문의</a></li>
                        </ul>
                    </div>
                </div>

                <div class="section online">
                    <div class="conts-area">
                        <div class="conts-box">
                            <form>
                                <div class="form-box">
                                    <label for="uName">이름<i class="im">&#42;</i></label>
                                    <input type="text" id="uName" ref="uName" placeholder="이름을 입력해 주세요.">
                                </div>

                                <div class="form-col">
                                    <p class="form-col-tit">휴대폰<i class="im">&#42;</i></p>
                                    <div class="col">
                                        <div class="form-select">
                                        <label for="pNum1" class="blind">휴대폰</label>
                                        <select id="pNum1" ref="pNum1">
                                            <option value="" selected="selected">번호선택</option>
                                            <option value="010">010</option>
                                            <option value="011">011</option>
                                        </select>
                                        </div>  
                                        <span class="unit">-</span>
                                        <div class="form-box">
                                            <label for="pNum2" class="blind">번호</label>
                                            <input type="tel" id="pNum2" ref="pNum2" maxlength="4" v-model="inputPnum2" @input="inputPnum2 = $event.target.value.replace(/[^0-9]/g, '')">
                                        </div>
                                        <span class="unit">-</span>
                                        <div class="form-box">
                                            <label for="pNum3" class="blind">번호</label>
                                            <input type="tel" id="pNum3" ref="pNum3" maxlength="4" v-model="inputPnum3" @input="inputPnum3 = $event.target.value.replace(/[^0-9]/g, '')">
                                        </div>
                                    </div>
                                </div>

                                <div class="form-box">
                                    <label for="mSubject">제목<i class="im">&#42;</i></label>
                                    <input type="text" id="mSubject" ref="mSubject" placeholder="제목을 입력해 주세요.">
                                </div>
                                <div class="form-box">
                                    <label for="mContent">내용<i class="im">&#42;</i></label>
                                    <textarea id="mContent" ref="mContent" placeholder="내용을 입력해 주세요."></textarea>
                                </div>

                            </form>
                        </div>

                    </div>
                </div>

                <div class="section privacy">
                    <div class="conts-box">
                        <p class="title">개인정보 수집 및 이용에 대한 안내</p>
                        <p class="txt">(주)소프트엠파이어에서는 기업/단체 및 개인의 정보 수집 및 이용 등 처리에 있어 아래의 사항을 관계법령에 따라 고지하고 안내해 드립니다.</p>
                        <ol class="list">
                            <li>정보수집의 이용 목적 : 상담 및 진행</li>
                            <li>수집/이용 항목 : 이름, 일반전화, 휴대전화, 이메일, 상담내용</li>
                            <li>보유 및 이용기간 : 상담 종료후 6개월, 정보제공자의 삭제 요청시 즉시</li>
                            <li>개인정보처리담당 : 전화 031-715-2100 / 이메일 help@softempire.co.kr</li>
                        </ol>
                        <div class="checkbox-area">
                            <div>
                            <input type="checkbox" id="chk1" ref="privacyChk">
                            <label for="chk1">개인정보 수집 및 이용에 동의합니다.</label>
                            </div>
                            <span class="txt-pop" @click="onModalOpen">내용보기</span>
                        </div>
                    </div>
                    
                    <div class="btn-area d-flex-center">
                        <button type="button" title="문의하기" class="btn btn-primary" @click="onComplated">
                            문의하기 <i class="btn-ico-r"><img src="@/assets/images/contents/ico_right_bg.png" alt="arrow"></i>
                        </button>
                    </div>
                </div>

                <!-- <div class="section online">
                    <BoardWrite
                        :bbsType="boardCategory"
                    />                    
                </div> -->

            </div>
            <!-- //section -->

        </div>
        <!-- //contents end -->
    </div>
    <!-- //container end -->

    <Footer/>

    <PrivacyModal
        v-if="showModal"
        @close="closeModal" 
        @ok="okModal" 
        :titleTxt="txtTitle_alert"
    />

</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted, getCurrentInstance, computed } from 'vue';
import { useRouter }from 'vue-router'
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import SubTitle from '@/components/SubTitleComponent.vue';
import PrivacyModal from '@/components/popups/PrivacyModal.vue';
import axios from '/axios';

export default {
    name: 'OnlineMobile',
    components:{
        Header,
        Footer,
        SubTitle,
        PrivacyModal,
    }, 
    setup() {
        const store = useStore();
        const router = useRouter();
        const emitter = getCurrentInstance().appContext.config.globalProperties.emitter;
        const boardCategory = ref('mobile');
        const uName = ref('');
        const pNum1 = ref('');
        const pNum2 = ref('');
        const pNum3 = ref('');
        const mSubject = ref('');
        const mContent = ref('');
        const privacyChk = ref(false)
        const inputPnum2 = ref(null);
        const inputPnum3 = ref(null);
        const isLogin = computed(() => store.state.isLogin);
        const showModal = ref(false);
        const txtTitle_alert = ref('개인정보취급방침');

        store.commit('CHANGE_CURRENT_PAGE', 'Online');

        onMounted(() => {
            // console.log('Online:', route.params.id)
        });


        const onComplated = async() => {
            // console.log('onComplated', '작성완료:', uName.value.value, chk1.value, uPw.value.value, qnaSubject.value.value, qnaContent.value.value, qnaFileData.value, qnaIsScret);            

            if( boardValidation() ) {
                var formData = new FormData();
                            
                formData.append('category', boardCategory.value);
                formData.append('name', uName.value.value);
                formData.append('subject', mSubject.value.value);
                formData.append('contents', mContent.value.value);
                formData.append('upfile', '');

                if(pNum1.value.value !== '' && pNum2.value.value !== '' && pNum3.value.value !== '' ) {
                    formData.append('phone', `${pNum1.value.value}-${pNum2.value.value}-${pNum3.value.value}`);
                }

                // console.log('onComplated1:', formData)
                // console.log('onComplated1:', boardCategory.value, uName.value.value, mContent.value.value)
                // console.log('onComplated2:', `${pNum1.value.value}-${pNum2.value.value}-${pNum3.value.value}`)

                axios.post('/board/counselingwrite', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then(function(response) {
                    let res = response.data;
                    // console.log("json: ", res);

                    if (res.returnCode == 1) {
                        if (res.resultMap.returnVal == 1) {
                            // console.log("sueccess:");
                            emitter.emit('EVENT_ALERT',{ title:"알림", body:"문의 등록 완료되었습니다." });
                            router.push({
                                name: 'OnlineEmail'
                            })
                            resetFormFeild();
                        } else {
                            // console.log(res.message);
                            emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        }
                    } else {
                        emitter.emit('EVENT_ALERT',{ title:"알림", body: res.message });
                        // console.log(res.message);
                    }
                })
                .catch(function(error) {
                    console.log(error)
                });

            }
        }

        const boardValidation = () => {
            const addWarningText = (field, fieldName) => {
                var warningText = field.parentNode.querySelector('.warning-txt');
                if(warningText) return false;

                const warningElement = document.createElement('span');
                warningElement.classList.add('warning-txt');
                warningElement.textContent = `* ${fieldName} 입력해주세요.`;
                field.parentNode.appendChild(warningElement);
            };

            const removeWarningText = () => {
                const warningText = document.querySelectorAll('.warning-txt');
                const warningClass = document.querySelectorAll('.warning');
                warningText.forEach(txt => txt.remove());
                warningClass.forEach(cl => cl.classList.remove('warning'));
            };

            const validateField = (field, fieldName) => {
                removeWarningText();

                if (!field.value || !field.value.trim()) {
                    addWarningText(field, fieldName);
                    field.classList.add('warning');
                    field.focus();
                    return false;
                } else {
                    return true;
                }
            };

            if (!validateField(uName.value, "이름을")) {
                return false;
            }
            if (!validateField(pNum1.value, "번호를")) {
                return false;
            }
            if (!validateField(pNum2.value, "번호를")) {
                return false;
            }
            if (!validateField(pNum3.value, "번호를")) {
                return false;
            }
            if (!validateField(mSubject.value, "제목을")) {
                return false;
            }
            if (!validateField(mContent.value, "내용을")) {
                return false;
            }
            if (privacyChk.value.checked == false) {
                emitter.emit('EVENT_ALERT',{ title:"알림", body:"개인정보 수집 및 이용에 동의하여 주세요." });
                document.querySelector('.checkbox-area').classList.add('warning');
                privacyChk.value.focus();
                return false;
            }

            return true;
        };

        const resetFormFeild = () => {
            uName.value.value = '';
            pNum1.value.value = '';
            pNum2.value.value = '';
            pNum3.value.value = '';
            mContent.value.value = '';
            privacyChk.value.checked = false;
            // qnaFileTarget.value.style.color = '#343434';
        }

        const onModalOpen = () => {
            showModal.value = true;
            document.body.classList.add('scr-none');
        }   

        const okModal = () => {
            showModal.value = false;
            document.body.classList.remove('scr-none');
        }   

        const closeModal = () => {
            showModal.value = false;
            document.body.classList.remove('scr-none');
        }

        const onlineListGo = () => {
            if(isLogin.value) {
                router.push({
                    name:'OnlineList'
                });                
            }
        }        

        return {
            showModal,
            okModal,
            closeModal,
            txtTitle_alert,
            onModalOpen,
            boardCategory,
            uName,
            pNum1,
            pNum2,
            pNum3,
            mSubject,
            mContent,
            privacyChk,
            inputPnum2,
            inputPnum3,
            onComplated,
            boardValidation,
            isLogin,
            onlineListGo,
        }
    }    
}
</script>
