import { createStore } from 'vuex'

export default createStore({
    state: {
        currentPage: 'Home',
        currentNoticePage: 1,
        currentQnaPage: 1,
        currentOnlinePage: 1,
        qnaCurrentPasswd: '',
        currentOnlineCategory: 'email',        
        isLogin: false,
    },
    getters: {
    },
    mutations: {
        CHANGE_IS_LOGIN(state, payload) {
            //console.log("CHANGE_IS_LOGIN:", state, payload)
            state.isLogin = payload;
        },      
        CHANGE_CURRENT_PAGE(state, payload) {
            state.currentPage = payload;
        },
        CHANGE_NOTICE_CURRENT_PAGE(state, payload) {
            state.currentNoticePage = payload;
        },
        CHANGE_QNA_CURRENT_PAGE(state, payload) {
            state.currentQnaPage = payload;
        },
        CHANGE_QNA_PASSWORD(state, payload) {
            state.qnaCurrentPasswd = payload;
        },  
        CHANGE_ONLINE_CURRENT_PAGE(state, payload) {
            state.currentOnlinePage = payload;
        },  
        CHANGE_ONLINE_CURRENT_CATEGORY(state, payload) {
            state.currentOnlineCategory = payload;
        },  
    },
    actions: {
    },
    modules: {
    }
})
